"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var StringArrayRotateFunctionTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayRotateFunctionTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("estraverse"));
const CustomCodeHelper_1 = require("../../enums/custom-code-helpers/CustomCodeHelper");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeAppender_1 = require("../../node/NodeAppender");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeLiteralUtils_1 = require("../../node/NodeLiteralUtils");
const NodeMetadata_1 = require("../../node/NodeMetadata");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const NodeUtils_1 = require("../../node/NodeUtils");
const NumericalExpressionDataToNodeConverter_1 = require("../../node/NumericalExpressionDataToNodeConverter");
let StringArrayRotateFunctionTransformer = StringArrayRotateFunctionTransformer_1 = class StringArrayRotateFunctionTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options, transformersRunner, stringArrayStorage, stringArrayStorageAnalyzer, customCodeHelperFactory, numberNumericalExpressionAnalyzer) {
        super(randomGenerator, options);
        this.stringArrayStorage = stringArrayStorage;
        this.stringArrayStorageAnalyzer = stringArrayStorageAnalyzer;
        this.transformersRunner = transformersRunner;
        this.customCodeHelperFactory = customCodeHelperFactory;
        this.numberNumericalExpressionAnalyzer = numberNumericalExpressionAnalyzer;
    }
    static isProgramNodeHasStringLiterals(programNode) {
        let hasStringLiterals = false;
        estraverse.traverse(programNode, {
            enter: (node) => {
                if (NodeGuards_1.NodeGuards.isLiteralNode(node)
                    && NodeLiteralUtils_1.NodeLiteralUtils.isStringLiteralNode(node)) {
                    hasStringLiterals = true;
                    return estraverse.VisitorOption.Break;
                }
            }
        });
        return hasStringLiterals;
    }
    getVisitor(nodeTransformationStage) {
        if (!this.options.rotateStringArray) {
            return null;
        }
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.StringArray:
                return {
                    enter: (node) => {
                        if (!NodeGuards_1.NodeGuards.isProgramNode(node)) {
                            return node;
                        }
                        if (!StringArrayRotateFunctionTransformer_1.isProgramNodeHasStringLiterals(node)) {
                            return estraverse.VisitorOption.Break;
                        }
                        return this.transformNode(node);
                    }
                };
            default:
                return null;
        }
    }
    transformNode(programNode) {
        const stringArrayRotateFunctionNode = this.getStringArrayRotateFunctionNode();
        const wrappedStringArrayRotateFunctionNode = NodeFactory_1.NodeFactory.programNode([
            stringArrayRotateFunctionNode
        ]);
        NodeUtils_1.NodeUtils.parentizeAst(wrappedStringArrayRotateFunctionNode);
        const transformationStages = [
            NodeTransformationStage_1.NodeTransformationStage.Preparing,
            NodeTransformationStage_1.NodeTransformationStage.Converting,
            NodeTransformationStage_1.NodeTransformationStage.RenameIdentifiers,
            NodeTransformationStage_1.NodeTransformationStage.Finalizing
        ];
        for (const transformationStage of transformationStages) {
            this.transformersRunner.transform(wrappedStringArrayRotateFunctionNode, StringArrayRotateFunctionTransformer_1.stringArrayRotateFunctionTransformers, transformationStage);
        }
        estraverse.traverse(wrappedStringArrayRotateFunctionNode, {
            enter: (node) => {
                if (!NodeGuards_1.NodeGuards.isLiteralNode(node)
                    || !NodeLiteralUtils_1.NodeLiteralUtils.isStringLiteralNode(node)) {
                    return;
                }
                if (this.isComparisonExpressionStringLiteralNode(node)) {
                    this.stringArrayStorageAnalyzer.addItemDataForLiteralNode(node);
                }
                else {
                    NodeMetadata_1.NodeMetadata.set(node, { ignoredNode: true });
                }
            }
        });
        NodeAppender_1.NodeAppender.prepend(programNode, [stringArrayRotateFunctionNode]);
        return programNode;
    }
    getStringArrayRotateFunctionNode() {
        const comparisonValue = this.randomGenerator.getRandomInteger(100000, 1000000);
        const comparisonExpressionNumberNumericalExpressionData = this.numberNumericalExpressionAnalyzer.analyze(comparisonValue, StringArrayRotateFunctionTransformer_1.comparisonExpressionAdditionalPartsCount);
        const comparisonExpressionNode = NumericalExpressionDataToNodeConverter_1.NumericalExpressionDataToNodeConverter.convertIntegerNumberData(comparisonExpressionNumberNumericalExpressionData, ((number, isPositiveNumber) => {
            const literalNode = NodeFactory_1.NodeFactory.literalNode(`${number}${this.randomGenerator.getRandomString(6)}`);
            const parseIntCallExpression = NodeFactory_1.NodeFactory.callExpressionNode(NodeFactory_1.NodeFactory.identifierNode('parseInt'), [literalNode]);
            return isPositiveNumber
                ? parseIntCallExpression
                : NodeFactory_1.NodeFactory.unaryExpressionNode('-', parseIntCallExpression);
        }));
        const stringArrayRotateFunctionCodeHelper = this.customCodeHelperFactory(CustomCodeHelper_1.CustomCodeHelper.StringArrayRotateFunction);
        stringArrayRotateFunctionCodeHelper.initialize(this.stringArrayStorage.getStorageName(), comparisonValue, comparisonExpressionNode);
        return stringArrayRotateFunctionCodeHelper.getNode()[0];
    }
    isComparisonExpressionStringLiteralNode(stringLiteralNode) {
        return /\d/.test(stringLiteralNode.value);
    }
};
StringArrayRotateFunctionTransformer.stringArrayRotateFunctionTransformers = [
    NodeTransformer_1.NodeTransformer.BooleanLiteralTransformer,
    NodeTransformer_1.NodeTransformer.MemberExpressionTransformer,
    NodeTransformer_1.NodeTransformer.NumberLiteralTransformer,
    NodeTransformer_1.NodeTransformer.NumberToNumericalExpressionTransformer,
    NodeTransformer_1.NodeTransformer.ParentificationTransformer,
    NodeTransformer_1.NodeTransformer.ScopeIdentifiersTransformer
];
StringArrayRotateFunctionTransformer.comparisonExpressionAdditionalPartsCount = 7;
StringArrayRotateFunctionTransformer = StringArrayRotateFunctionTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformersRunner)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorageAnalyzer)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICustomCodeHelper)),
    __param(6, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.INumberNumericalExpressionAnalyzer)),
    __metadata("design:paramtypes", [Object, Object, Object, Object, Object, Function, Object])
], StringArrayRotateFunctionTransformer);
exports.StringArrayRotateFunctionTransformer = StringArrayRotateFunctionTransformer;
