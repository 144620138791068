"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var ObjectExpressionKeysTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectExpressionKeysTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("estraverse"));
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const ObjectExpressionExtractor_1 = require("../../enums/node-transformers/converting-transformers/properties-extractors/ObjectExpressionExtractor");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeStatementUtils_1 = require("../../node/NodeStatementUtils");
let ObjectExpressionKeysTransformer = ObjectExpressionKeysTransformer_1 = class ObjectExpressionKeysTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(objectExpressionExtractorFactory, randomGenerator, options) {
        super(randomGenerator, options);
        this.objectExpressionExtractorFactory = objectExpressionExtractorFactory;
    }
    static isProhibitedObjectExpressionNode(objectExpressionNode, objectExpressionParentNode, objectExpressionHostStatement) {
        return ObjectExpressionKeysTransformer_1.isReferencedIdentifierName(objectExpressionNode, objectExpressionHostStatement)
            || ObjectExpressionKeysTransformer_1.isProhibitedArrowFunctionExpression(objectExpressionNode, objectExpressionParentNode)
            || ObjectExpressionKeysTransformer_1.isProhibitedSequenceExpression(objectExpressionNode, objectExpressionHostStatement);
    }
    static isReferencedIdentifierName(objectExpressionNode, objectExpressionHostNode) {
        const identifierNamesSet = [];
        let isReferencedIdentifierName = false;
        let isCurrentNode = false;
        estraverse.traverse(objectExpressionHostNode, {
            enter: (node) => {
                if (node === objectExpressionNode) {
                    isCurrentNode = true;
                }
                if (!NodeGuards_1.NodeGuards.isIdentifierNode(node)) {
                    return;
                }
                if (!isCurrentNode) {
                    identifierNamesSet.push(node.name);
                    return;
                }
                if (identifierNamesSet.includes(node.name)) {
                    isReferencedIdentifierName = true;
                }
            },
            leave: (node) => {
                if (node === objectExpressionNode) {
                    isCurrentNode = false;
                    return estraverse.VisitorOption.Break;
                }
            }
        });
        return isReferencedIdentifierName;
    }
    static isProhibitedArrowFunctionExpression(objectExpressionNode, objectExpressionNodeParentNode) {
        return NodeGuards_1.NodeGuards.isArrowFunctionExpressionNode(objectExpressionNodeParentNode)
            && objectExpressionNodeParentNode.body === objectExpressionNode;
    }
    static isProhibitedSequenceExpression(objectExpressionNode, objectExpressionHostNode) {
        return NodeGuards_1.NodeGuards.isExpressionStatementNode(objectExpressionHostNode)
            && NodeGuards_1.NodeGuards.isSequenceExpressionNode(objectExpressionHostNode.expression)
            && objectExpressionHostNode.expression.expressions.some((expressionNode) => NodeGuards_1.NodeGuards.isCallExpressionNode(expressionNode)
                && NodeGuards_1.NodeGuards.isSuperNode(expressionNode.callee));
    }
    getVisitor(nodeTransformationStage) {
        if (!this.options.transformObjectKeys) {
            return null;
        }
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Converting:
                return {
                    leave: (node, parentNode) => {
                        if (parentNode
                            && NodeGuards_1.NodeGuards.isObjectExpressionNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(objectExpressionNode, parentNode) {
        if (!objectExpressionNode.properties.length) {
            return objectExpressionNode;
        }
        const hostStatement = NodeStatementUtils_1.NodeStatementUtils.getRootStatementOfNode(objectExpressionNode);
        if (ObjectExpressionKeysTransformer_1.isProhibitedObjectExpressionNode(objectExpressionNode, parentNode, hostStatement)) {
            return objectExpressionNode;
        }
        return this.applyObjectExpressionKeysExtractorsRecursive(ObjectExpressionKeysTransformer_1.objectExpressionExtractorNames, objectExpressionNode, hostStatement);
    }
    applyObjectExpressionKeysExtractorsRecursive(objectExpressionExtractorNames, objectExpressionNode, hostStatement) {
        const newObjectExpressionExtractorNames = [...objectExpressionExtractorNames];
        const objectExpressionExtractor = newObjectExpressionExtractorNames.shift();
        if (!objectExpressionExtractor) {
            return objectExpressionNode;
        }
        const { nodeToReplace, objectExpressionHostStatement: newObjectExpressionHostStatement, objectExpressionNode: newObjectExpressionNode } = this.objectExpressionExtractorFactory(objectExpressionExtractor)
            .extract(objectExpressionNode, hostStatement);
        this.applyObjectExpressionKeysExtractorsRecursive(newObjectExpressionExtractorNames, newObjectExpressionNode, newObjectExpressionHostStatement);
        return nodeToReplace;
    }
};
ObjectExpressionKeysTransformer.objectExpressionExtractorNames = [
    ObjectExpressionExtractor_1.ObjectExpressionExtractor.ObjectExpressionToVariableDeclarationExtractor,
    ObjectExpressionExtractor_1.ObjectExpressionExtractor.BasePropertiesExtractor
];
ObjectExpressionKeysTransformer = ObjectExpressionKeysTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IObjectExpressionExtractor)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object])
], ObjectExpressionKeysTransformer);
exports.ObjectExpressionKeysTransformer = ObjectExpressionKeysTransformer;
