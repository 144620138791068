"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var OptionsNormalizer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionsNormalizer = void 0;
const inversify_1 = require("inversify");
const ControlFlowFlatteningThresholdRule_1 = require("./normalizer-rules/ControlFlowFlatteningThresholdRule");
const DeadCodeInjectionRule_1 = require("./normalizer-rules/DeadCodeInjectionRule");
const DeadCodeInjectionThresholdRule_1 = require("./normalizer-rules/DeadCodeInjectionThresholdRule");
const DomainLockRule_1 = require("./normalizer-rules/DomainLockRule");
const InputFileNameRule_1 = require("./normalizer-rules/InputFileNameRule");
const SeedRule_1 = require("./normalizer-rules/SeedRule");
const SelfDefendingRule_1 = require("./normalizer-rules/SelfDefendingRule");
const SourceMapBaseUrlRule_1 = require("./normalizer-rules/SourceMapBaseUrlRule");
const SourceMapFileNameRule_1 = require("./normalizer-rules/SourceMapFileNameRule");
const SplitStringsChunkLengthRule_1 = require("./normalizer-rules/SplitStringsChunkLengthRule");
const StringArrayRule_1 = require("./normalizer-rules/StringArrayRule");
const StringArrayEncodingRule_1 = require("./normalizer-rules/StringArrayEncodingRule");
const StringArrayWappersChainedCalls_1 = require("./normalizer-rules/StringArrayWappersChainedCalls");
let OptionsNormalizer = OptionsNormalizer_1 = class OptionsNormalizer {
    normalize(options) {
        let normalizedOptions = Object.assign({}, options);
        for (const normalizerRule of OptionsNormalizer_1.normalizerRules) {
            normalizedOptions = normalizerRule(normalizedOptions);
        }
        return normalizedOptions;
    }
};
OptionsNormalizer.normalizerRules = [
    ControlFlowFlatteningThresholdRule_1.ControlFlowFlatteningThresholdRule,
    DeadCodeInjectionRule_1.DeadCodeInjectionRule,
    DeadCodeInjectionThresholdRule_1.DeadCodeInjectionThresholdRule,
    DomainLockRule_1.DomainLockRule,
    InputFileNameRule_1.InputFileNameRule,
    SeedRule_1.SeedRule,
    SelfDefendingRule_1.SelfDefendingRule,
    SourceMapBaseUrlRule_1.SourceMapBaseUrlRule,
    SourceMapFileNameRule_1.SourceMapFileNameRule,
    SplitStringsChunkLengthRule_1.SplitStringsChunkLengthRule,
    StringArrayRule_1.StringArrayRule,
    StringArrayEncodingRule_1.StringArrayEncodingRule,
    StringArrayWappersChainedCalls_1.StringArrayWrappersChainedCallsRule,
];
OptionsNormalizer = OptionsNormalizer_1 = __decorate([
    (0, inversify_1.injectable)()
], OptionsNormalizer);
exports.OptionsNormalizer = OptionsNormalizer;
