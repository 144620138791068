"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var LogicalExpressionControlFlowReplacer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogicalExpressionControlFlowReplacer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const ControlFlowCustomNode_1 = require("../../../enums/custom-nodes/ControlFlowCustomNode");
const ExpressionWithOperatorControlFlowReplacer_1 = require("./ExpressionWithOperatorControlFlowReplacer");
const NodeGuards_1 = require("../../../node/NodeGuards");
const NodeUtils_1 = require("../../../node/NodeUtils");
let LogicalExpressionControlFlowReplacer = LogicalExpressionControlFlowReplacer_1 = class LogicalExpressionControlFlowReplacer extends ExpressionWithOperatorControlFlowReplacer_1.ExpressionWithOperatorControlFlowReplacer {
    constructor(controlFlowCustomNodeFactory, randomGenerator, options) {
        super(controlFlowCustomNodeFactory, randomGenerator, options);
    }
    replace(logicalExpressionNode, parentNode, controlFlowStorage) {
        if (this.checkForProhibitedExpressions(logicalExpressionNode.left, logicalExpressionNode.right)) {
            return logicalExpressionNode;
        }
        const operator = logicalExpressionNode.operator;
        const logicalExpressionFunctionCustomNode = this.controlFlowCustomNodeFactory(ControlFlowCustomNode_1.ControlFlowCustomNode.LogicalExpressionFunctionNode);
        logicalExpressionFunctionCustomNode.initialize(operator);
        const storageKey = this.insertCustomNodeToControlFlowStorage(logicalExpressionFunctionCustomNode, controlFlowStorage, operator, LogicalExpressionControlFlowReplacer_1.usingExistingIdentifierChance);
        return this.getControlFlowStorageCallNode(controlFlowStorage.getStorageId(), storageKey, logicalExpressionNode.left, logicalExpressionNode.right);
    }
    checkForProhibitedExpressions(leftExpression, rightExpression) {
        return [leftExpression, rightExpression].some((expressionNode) => {
            let nodeForCheck;
            if (!NodeGuards_1.NodeGuards.isUnaryExpressionNode(expressionNode)) {
                nodeForCheck = expressionNode;
            }
            else {
                nodeForCheck = NodeUtils_1.NodeUtils.getUnaryExpressionArgumentNode(expressionNode);
            }
            return !NodeGuards_1.NodeGuards.isLiteralNode(nodeForCheck) &&
                !NodeGuards_1.NodeGuards.isIdentifierNode(nodeForCheck) &&
                !NodeGuards_1.NodeGuards.isObjectExpressionNode(nodeForCheck) &&
                !NodeGuards_1.NodeGuards.isExpressionStatementNode(nodeForCheck);
        });
    }
};
LogicalExpressionControlFlowReplacer.usingExistingIdentifierChance = 0.5;
LogicalExpressionControlFlowReplacer = LogicalExpressionControlFlowReplacer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowCustomNode)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object])
], LogicalExpressionControlFlowReplacer);
exports.LogicalExpressionControlFlowReplacer = LogicalExpressionControlFlowReplacer;
