"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayRule = void 0;
const StringArrayEncoding_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayEncoding");
const StringArrayRule = (options) => {
    if (!options.stringArray) {
        options = Object.assign(Object.assign({}, options), { rotateStringArray: false, shuffleStringArray: false, stringArray: false, stringArrayEncoding: [
                StringArrayEncoding_1.StringArrayEncoding.None
            ], stringArrayIndexShift: false, stringArrayWrappersChainedCalls: false, stringArrayWrappersCount: 0, stringArrayThreshold: 0 });
    }
    return options;
};
exports.StringArrayRule = StringArrayRule;
