"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storagesModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const ControlFlowStorage_1 = require("../../../storages/custom-nodes/ControlFlowStorage");
const CustomCodeHelperGroupStorage_1 = require("../../../storages/custom-code-helpers/CustomCodeHelperGroupStorage");
const LiteralNodesCacheStorage_1 = require("../../../storages/string-array-transformers/LiteralNodesCacheStorage");
const StringArrayScopeCallsWrapperLexicalScopeDataStorage_1 = require("../../../storages/string-array-transformers/StringArrayScopeCallsWrapperLexicalScopeDataStorage");
const StringArrayScopeCallsWrapperNamesDataStorage_1 = require("../../../storages/string-array-transformers/StringArrayScopeCallsWrapperNamesDataStorage");
const StringArrayStorage_1 = require("../../../storages/string-array-transformers/StringArrayStorage");
const VisitedLexicalScopeNodesStackStorage_1 = require("../../../storages/string-array-transformers/VisitedLexicalScopeNodesStackStorage");
exports.storagesModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.TCustomNodeGroupStorage)
        .to(CustomCodeHelperGroupStorage_1.CustomCodeHelperGroupStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ILiteralNodesCacheStorage)
        .to(LiteralNodesCacheStorage_1.LiteralNodesCacheStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)
        .to(StringArrayStorage_1.StringArrayStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayScopeCallsWrapperLexicalScopeDataStorage)
        .to(StringArrayScopeCallsWrapperLexicalScopeDataStorage_1.StringArrayScopeCallsWrapperLexicalScopeDataStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayScopeCallsWrapperNamesDataStorage)
        .to(StringArrayScopeCallsWrapperNamesDataStorage_1.StringArrayScopeCallsWrapperNamesDataStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IVisitedLexicalScopeNodesStackStorage)
        .to(VisitedLexicalScopeNodesStackStorage_1.VisitedLexicalScopeNodesStackStorage)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__TControlFlowStorage)
        .toConstructor(ControlFlowStorage_1.ControlFlowStorage);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__TControlFlowStorage)
        .toFactory((context) => {
        return () => {
            const constructor = context.container
                .get(ServiceIdentifiers_1.ServiceIdentifiers.Newable__TControlFlowStorage);
            const randomGenerator = context.container
                .get(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator);
            const options = context.container
                .get(ServiceIdentifiers_1.ServiceIdentifiers.IOptions);
            const storage = new constructor(randomGenerator, options);
            storage.initialize();
            return storage;
        };
    });
});
