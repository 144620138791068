"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renameIdentifiersTransformersModule = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const IdentifierReplacer_1 = require("../../../node-transformers/rename-identifiers-transformers/replacer/IdentifierReplacer");
const LabeledStatementTransformer_1 = require("../../../node-transformers/rename-identifiers-transformers/LabeledStatementTransformer");
const ScopeIdentifiersTransformer_1 = require("../../../node-transformers/rename-identifiers-transformers/ScopeIdentifiersTransformer");
const ScopeThroughIdentifiersTransformer_1 = require("../../../node-transformers/rename-identifiers-transformers/ScopeThroughIdentifiersTransformer");
exports.renameIdentifiersTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(LabeledStatementTransformer_1.LabeledStatementTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.LabeledStatementTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ScopeIdentifiersTransformer_1.ScopeIdentifiersTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ScopeIdentifiersTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ScopeThroughIdentifiersTransformer_1.ScopeThroughIdentifiersTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ScopeThroughIdentifiersTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierReplacer)
        .to(IdentifierReplacer_1.IdentifierReplacer)
        .inSingletonScope();
});
