"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var DeadCodeInjectionTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeadCodeInjectionTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("estraverse"));
const DeadCodeInjectionCustomNode_1 = require("../../enums/custom-nodes/DeadCodeInjectionCustomNode");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const NodeType_1 = require("../../enums/node/NodeType");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeStatementUtils_1 = require("../../node/NodeStatementUtils");
const NodeUtils_1 = require("../../node/NodeUtils");
let DeadCodeInjectionTransformer = DeadCodeInjectionTransformer_1 = class DeadCodeInjectionTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(deadCodeInjectionCustomNodeFactory, transformersRunner, randomGenerator, options) {
        super(randomGenerator, options);
        this.deadCodeInjectionRootAstHostNodeSet = new Set();
        this.collectedBlockStatements = [];
        this.collectedBlockStatementsTotalLength = 0;
        this.deadCodeInjectionCustomNodeFactory = deadCodeInjectionCustomNodeFactory;
        this.transformersRunner = transformersRunner;
    }
    static isProhibitedNodeInsideCollectedBlockStatement(targetNode) {
        return NodeGuards_1.NodeGuards.isFunctionDeclarationNode(targetNode)
            || NodeGuards_1.NodeGuards.isBreakStatementNode(targetNode)
            || NodeGuards_1.NodeGuards.isContinueStatementNode(targetNode)
            || NodeGuards_1.NodeGuards.isAwaitExpressionNode(targetNode)
            || NodeGuards_1.NodeGuards.isYieldExpressionNode(targetNode)
            || NodeGuards_1.NodeGuards.isSuperNode(targetNode)
            || (NodeGuards_1.NodeGuards.isForOfStatementNode(targetNode) && targetNode.await);
    }
    static isScopeHoistingFunctionDeclaration(targetNode) {
        if (!NodeGuards_1.NodeGuards.isFunctionDeclarationNode(targetNode)) {
            return false;
        }
        const scopeNode = NodeStatementUtils_1.NodeStatementUtils.getScopeOfNode(targetNode);
        const scopeBody = !NodeGuards_1.NodeGuards.isSwitchCaseNode(scopeNode)
            ? scopeNode.body
            : scopeNode.consequent;
        const indexInScope = scopeBody.indexOf(targetNode);
        if (indexInScope === 0) {
            return false;
        }
        const slicedBody = scopeBody.slice(0, indexInScope);
        const hostBlockStatementNode = NodeFactory_1.NodeFactory.blockStatementNode(slicedBody);
        const functionDeclarationName = targetNode.id.name;
        let isScopeHoistedFunctionDeclaration = false;
        estraverse.traverse(hostBlockStatementNode, {
            enter: (node) => {
                if (NodeGuards_1.NodeGuards.isIdentifierNode(node) && node.name === functionDeclarationName) {
                    isScopeHoistedFunctionDeclaration = true;
                    return estraverse.VisitorOption.Break;
                }
            }
        });
        return isScopeHoistedFunctionDeclaration;
    }
    static isValidCollectedBlockStatementNode(blockStatementNode) {
        if (!blockStatementNode.body.length) {
            return false;
        }
        let nestedBlockStatementsCount = 0;
        let isValidBlockStatementNode = true;
        estraverse.traverse(blockStatementNode, {
            enter: (node) => {
                if (NodeGuards_1.NodeGuards.isBlockStatementNode(node)) {
                    nestedBlockStatementsCount++;
                }
                if (nestedBlockStatementsCount > DeadCodeInjectionTransformer_1.maxNestedBlockStatementsCount
                    || DeadCodeInjectionTransformer_1.isProhibitedNodeInsideCollectedBlockStatement(node)
                    || DeadCodeInjectionTransformer_1.isScopeHoistingFunctionDeclaration(node)) {
                    isValidBlockStatementNode = false;
                    return estraverse.VisitorOption.Break;
                }
            }
        });
        return isValidBlockStatementNode;
    }
    static isValidWrappedBlockStatementNode(blockStatementNode) {
        if (!blockStatementNode.body.length) {
            return false;
        }
        let isValidBlockStatementNode = true;
        estraverse.traverse(blockStatementNode, {
            enter: (node) => {
                if (DeadCodeInjectionTransformer_1.isScopeHoistingFunctionDeclaration(node)) {
                    isValidBlockStatementNode = false;
                    return estraverse.VisitorOption.Break;
                }
            }
        });
        if (!isValidBlockStatementNode) {
            return false;
        }
        const parentNodeWithStatements = NodeStatementUtils_1.NodeStatementUtils
            .getParentNodeWithStatements(blockStatementNode);
        return parentNodeWithStatements.type !== NodeType_1.NodeType.Program;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.DeadCodeInjection:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isProgramNode(node)) {
                            this.prepareNode(node, parentNode);
                            return node;
                        }
                    },
                    leave: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isBlockStatementNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            case NodeTransformationStage_1.NodeTransformationStage.Finalizing:
                if (!this.deadCodeInjectionRootAstHostNodeSet.size) {
                    return null;
                }
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && this.isDeadCodeInjectionRootAstHostNode(node)) {
                            return this.restoreNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    prepareNode(programNode, parentNode) {
        estraverse.traverse(programNode, {
            enter: (node) => {
                if (!NodeGuards_1.NodeGuards.isBlockStatementNode(node)) {
                    return;
                }
                const clonedBlockStatementNode = NodeUtils_1.NodeUtils.clone(node);
                if (!DeadCodeInjectionTransformer_1.isValidCollectedBlockStatementNode(clonedBlockStatementNode)) {
                    return;
                }
                const transformedBlockStatementNode = this.makeClonedBlockStatementNodeUnique(clonedBlockStatementNode);
                this.collectedBlockStatements.push(transformedBlockStatementNode);
            }
        });
        this.collectedBlockStatementsTotalLength = this.collectedBlockStatements.length;
    }
    transformNode(blockStatementNode, parentNode) {
        const canBreakTraverse = !this.collectedBlockStatements.length
            || this.collectedBlockStatementsTotalLength < DeadCodeInjectionTransformer_1.minCollectedBlockStatementsCount;
        if (canBreakTraverse) {
            return estraverse.VisitorOption.Break;
        }
        if (this.randomGenerator.getMathRandom() > this.options.deadCodeInjectionThreshold
            || !DeadCodeInjectionTransformer_1.isValidWrappedBlockStatementNode(blockStatementNode)) {
            return blockStatementNode;
        }
        const minInteger = 0;
        const maxInteger = this.collectedBlockStatements.length - 1;
        const randomIndex = this.randomGenerator.getRandomInteger(minInteger, maxInteger);
        const randomBlockStatementNode = this.collectedBlockStatements.splice(randomIndex, 1)[0];
        const isDuplicateBlockStatementNodes = randomBlockStatementNode === blockStatementNode;
        if (isDuplicateBlockStatementNodes) {
            return blockStatementNode;
        }
        return this.replaceBlockStatementNode(blockStatementNode, randomBlockStatementNode, parentNode);
    }
    restoreNode(deadCodeInjectionRootAstHostNode, parentNode) {
        const hostNodeFirstStatement = deadCodeInjectionRootAstHostNode.body[0];
        if (!NodeGuards_1.NodeGuards.isFunctionDeclarationNode(hostNodeFirstStatement)) {
            throw new Error('Wrong dead code injection root AST host node. Host node should contain `FunctionDeclaration` node');
        }
        return hostNodeFirstStatement.body;
    }
    isDeadCodeInjectionRootAstHostNode(node) {
        return NodeGuards_1.NodeGuards.isBlockStatementNode(node) && this.deadCodeInjectionRootAstHostNodeSet.has(node);
    }
    makeClonedBlockStatementNodeUnique(clonedBlockStatementNode) {
        const hostNode = NodeFactory_1.NodeFactory.programNode([
            NodeFactory_1.NodeFactory.expressionStatementNode(NodeFactory_1.NodeFactory.functionExpressionNode([], clonedBlockStatementNode))
        ]);
        NodeUtils_1.NodeUtils.parentizeAst(hostNode);
        NodeUtils_1.NodeUtils.parentizeNode(hostNode, hostNode);
        this.transformersRunner.transform(hostNode, DeadCodeInjectionTransformer_1.transformersToRenameBlockScopeIdentifiers, NodeTransformationStage_1.NodeTransformationStage.RenameIdentifiers);
        return clonedBlockStatementNode;
    }
    replaceBlockStatementNode(blockStatementNode, randomBlockStatementNode, parentNode) {
        const deadCodeInjectionRootAstHostNode = NodeFactory_1.NodeFactory.blockStatementNode([
            NodeFactory_1.NodeFactory.functionDeclarationNode(DeadCodeInjectionTransformer_1.deadCodeInjectionRootAstHostNodeName, [], randomBlockStatementNode)
        ]);
        this.deadCodeInjectionRootAstHostNodeSet.add(deadCodeInjectionRootAstHostNode);
        const blockStatementDeadCodeInjectionCustomNode = this.deadCodeInjectionCustomNodeFactory(DeadCodeInjectionCustomNode_1.DeadCodeInjectionCustomNode.BlockStatementDeadCodeInjectionNode);
        blockStatementDeadCodeInjectionCustomNode.initialize(blockStatementNode, deadCodeInjectionRootAstHostNode);
        const newBlockStatementNode = blockStatementDeadCodeInjectionCustomNode.getNode()[0];
        NodeUtils_1.NodeUtils.parentizeNode(newBlockStatementNode, parentNode);
        return newBlockStatementNode;
    }
};
DeadCodeInjectionTransformer.deadCodeInjectionRootAstHostNodeName = 'deadCodeInjectionRootAstHostNode';
DeadCodeInjectionTransformer.maxNestedBlockStatementsCount = 4;
DeadCodeInjectionTransformer.minCollectedBlockStatementsCount = 5;
DeadCodeInjectionTransformer.transformersToRenameBlockScopeIdentifiers = [
    NodeTransformer_1.NodeTransformer.LabeledStatementTransformer,
    NodeTransformer_1.NodeTransformer.ScopeIdentifiersTransformer,
    NodeTransformer_1.NodeTransformer.ScopeThroughIdentifiersTransformer
];
DeadCodeInjectionTransformer = DeadCodeInjectionTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IDeadCodeInjectionCustomNode)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformersRunner)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object, Object])
], DeadCodeInjectionTransformer);
exports.DeadCodeInjectionTransformer = DeadCodeInjectionTransformer;
