"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializable = void 0;
const decoratorName = 'initializable';
const defaultDescriptor = {
    configurable: true,
    enumerable: true
};
const initializedTargetMetadataKey = '_initialized';
const initializablePropertiesSetMetadataKey = '_initializablePropertiesSet';
const wrappedMethodsSetMetadataKey = '_wrappedMethodsSet';
const constructorMethodName = 'constructor';
const initializeMethodName = 'initialize';
function initializable() {
    return (target, propertyKey) => {
        const initializeMethod = target[initializeMethodName];
        if (!initializeMethod || typeof initializeMethod !== 'function') {
            throw new Error(`\`${initializeMethodName}\` method with initialization logic not ` +
                `found. \`@${decoratorName}\` decorator requires \`${initializeMethodName}\` method`);
        }
        initializeTargetMetadata(initializedTargetMetadataKey, false, target);
        initializeTargetMetadata(initializablePropertiesSetMetadataKey, new Set(), target);
        initializeTargetMetadata(wrappedMethodsSetMetadataKey, new Set(), target);
        wrapTargetMethodsInInitializedCheck(target);
        wrapInitializeMethodInInitializeCheck(target, propertyKey);
        return wrapInitializableProperty(target, propertyKey);
    };
}
exports.initializable = initializable;
function initializeTargetMetadata(metadataKey, metadataValue, target) {
    const hasInitializedMetadata = Reflect.hasMetadata(metadataKey, target);
    if (!hasInitializedMetadata) {
        Reflect.defineMetadata(metadataKey, metadataValue, target);
    }
}
function wrapTargetMethodsInInitializedCheck(target) {
    const ownPropertyNames = Object.getOwnPropertyNames(target);
    const prohibitedPropertyNames = new Set([initializeMethodName, constructorMethodName]);
    ownPropertyNames.forEach((propertyName) => {
        var _a;
        const initializablePropertiesSet = Reflect
            .getMetadata(initializablePropertiesSetMetadataKey, target);
        const wrappedMethodsSet = Reflect
            .getMetadata(wrappedMethodsSetMetadataKey, target);
        const isProhibitedPropertyName = prohibitedPropertyNames.has(propertyName)
            || initializablePropertiesSet.has(propertyName)
            || wrappedMethodsSet.has(propertyName);
        if (isProhibitedPropertyName) {
            return;
        }
        const targetProperty = target[propertyName];
        if (typeof targetProperty !== 'function') {
            return;
        }
        const methodDescriptor = (_a = Object
            .getOwnPropertyDescriptor(target, propertyName)) !== null && _a !== void 0 ? _a : defaultDescriptor;
        const originalMethod = methodDescriptor.value;
        Object.defineProperty(target, propertyName, Object.assign(Object.assign({}, methodDescriptor), { value() {
                if (!Reflect.getMetadata(initializedTargetMetadataKey, this)) {
                    throw new Error(`Class should be initialized with \`${initializeMethodName}()\` method`);
                }
                return originalMethod.apply(this, arguments);
            } }));
        wrappedMethodsSet.add(propertyName);
    });
}
function wrapInitializeMethodInInitializeCheck(target, propertyKey) {
    var _a;
    const methodDescriptor = (_a = Object
        .getOwnPropertyDescriptor(target, initializeMethodName)) !== null && _a !== void 0 ? _a : defaultDescriptor;
    const originalMethod = methodDescriptor.value;
    Object.defineProperty(target, initializeMethodName, Object.assign(Object.assign({}, methodDescriptor), { value: function () {
            Reflect.defineMetadata(initializedTargetMetadataKey, true, this);
            const result = originalMethod.apply(this, arguments);
            if (this[propertyKey]) { }
            return result;
        } }));
}
function wrapInitializableProperty(target, propertyKey) {
    var _a;
    const initializablePropertiesSet = Reflect
        .getMetadata(initializablePropertiesSetMetadataKey, target);
    initializablePropertiesSet.add(propertyKey);
    const initializablePropertyMetadataKey = `_${propertyKey.toString()}`;
    const propertyDescriptor = (_a = Object
        .getOwnPropertyDescriptor(target, initializablePropertyMetadataKey)) !== null && _a !== void 0 ? _a : defaultDescriptor;
    Object.defineProperty(target, propertyKey, Object.assign(Object.assign({}, propertyDescriptor), { get: function () {
            if (this[initializablePropertyMetadataKey] === undefined) {
                throw new Error(`Property \`${propertyKey.toString()}\` is not initialized! Initialize it first!`);
            }
            return this[initializablePropertyMetadataKey];
        }, set: function (newVal) {
            this[initializablePropertyMetadataKey] = newVal;
        } }));
    return propertyDescriptor;
}
