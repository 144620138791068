"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var MangledShuffledIdentifierNamesGenerator_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MangledShuffledIdentifierNamesGenerator = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NumbersString_1 = require("../../constants/NumbersString");
const AlphabetString_1 = require("../../constants/AlphabetString");
const AlphabetStringUppercase_1 = require("../../constants/AlphabetStringUppercase");
const MangledIdentifierNamesGenerator_1 = require("./MangledIdentifierNamesGenerator");
let MangledShuffledIdentifierNamesGenerator = MangledShuffledIdentifierNamesGenerator_1 = class MangledShuffledIdentifierNamesGenerator extends MangledIdentifierNamesGenerator_1.MangledIdentifierNamesGenerator {
    constructor(arrayUtils, randomGenerator, options) {
        super(randomGenerator, options);
        this.arrayUtils = arrayUtils;
    }
    initialize() {
        this.initializeNameSequence([
            ...`${NumbersString_1.numbersString}`,
            ...this.arrayUtils.shuffle([...`${AlphabetString_1.alphabetString}${AlphabetStringUppercase_1.alphabetStringUppercase}`])
        ]);
    }
    initializeNameSequence(nameSequence) {
        if (!this.getNameSequence()) {
            MangledShuffledIdentifierNamesGenerator_1.shuffledNameSequence = nameSequence;
        }
    }
    getNameSequence() {
        return MangledShuffledIdentifierNamesGenerator_1.shuffledNameSequence;
    }
    generateNewMangledName(previousMangledName) {
        return super.generateNewMangledName(previousMangledName);
    }
};
__decorate([
    (0, inversify_1.postConstruct)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MangledShuffledIdentifierNamesGenerator.prototype, "initialize", null);
MangledShuffledIdentifierNamesGenerator = MangledShuffledIdentifierNamesGenerator_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object, Object])
], MangledShuffledIdentifierNamesGenerator);
exports.MangledShuffledIdentifierNamesGenerator = MangledShuffledIdentifierNamesGenerator;
