"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayRotateFunctionTemplate = void 0;
function StringArrayRotateFunctionTemplate() {
    return `
        (function (array, comparisonValue) {
            while (true) {
                try {
                    const expression = {comparisonExpressionCode};
                                            
                    if (expression === comparisonValue) {
                        break;
                    } else {
                        array['push'](array['shift']());
                    }
                } catch (e) {
                    array['push'](array['shift']());
                }
            }
        })({stringArrayName}, {comparisonValue});
    `;
}
exports.StringArrayRotateFunctionTemplate = StringArrayRotateFunctionTemplate;
