"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MEDIUM_OBFUSCATION_PRESET = void 0;
const OptionsPreset_1 = require("../../enums/options/presets/OptionsPreset");
const StringArrayEncoding_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayEncoding");
const StringArrayWrappersType_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayWrappersType");
const LowObfuscation_1 = require("./LowObfuscation");
exports.MEDIUM_OBFUSCATION_PRESET = Object.freeze(Object.assign(Object.assign({}, LowObfuscation_1.LOW_OBFUSCATION_PRESET), { controlFlowFlattening: true, deadCodeInjection: true, numbersToExpressions: true, optionsPreset: OptionsPreset_1.OptionsPreset.MediumObfuscation, splitStrings: true, splitStringsChunkLength: 10, stringArrayEncoding: [
        StringArrayEncoding_1.StringArrayEncoding.Base64
    ], stringArrayWrappersCount: 2, stringArrayWrappersParametersMaxCount: 4, stringArrayWrappersType: StringArrayWrappersType_1.StringArrayWrappersType.Function, transformObjectKeys: true }));
