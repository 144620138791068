"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodeTransformersRunner = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../container/ServiceIdentifiers");
let CodeTransformersRunner = class CodeTransformersRunner {
    constructor(codeTransformerFactory, codeTransformerNamesGroupsBuilder) {
        this.codeTransformerFactory = codeTransformerFactory;
        this.codeTransformerNamesGroupsBuilder = codeTransformerNamesGroupsBuilder;
    }
    transform(code, codeTransformerNames, codeTransformationStage) {
        if (!codeTransformerNames.length) {
            return code;
        }
        const normalizedCodeTransformers = this.buildNormalizedCodeTransformers(codeTransformerNames, codeTransformationStage);
        const codeTransformerNamesGroups = this.codeTransformerNamesGroupsBuilder.build(normalizedCodeTransformers);
        for (const nodeTransformerNamesGroup of codeTransformerNamesGroups) {
            for (const nodeTransformerName of nodeTransformerNamesGroup) {
                const codeTransformer = normalizedCodeTransformers[nodeTransformerName];
                code = codeTransformer.transformCode(code, codeTransformationStage);
            }
        }
        return code;
    }
    buildNormalizedCodeTransformers(codeTransformerNames, codeTransformationStage) {
        return codeTransformerNames
            .reduce((acc, codeTransformerName) => {
            const codeTransformer = this.codeTransformerFactory(codeTransformerName);
            return Object.assign(Object.assign({}, acc), { [codeTransformerName]: codeTransformer });
        }, {});
    }
};
CodeTransformersRunner = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICodeTransformer)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICodeTransformerNamesGroupsBuilder)),
    __metadata("design:paramtypes", [Function, Object])
], CodeTransformersRunner);
exports.CodeTransformersRunner = CodeTransformersRunner;
