"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentifierReplacer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const NodeFactory_1 = require("../../../node/NodeFactory");
let IdentifierReplacer = class IdentifierReplacer {
    constructor(identifierNamesGeneratorFactory, options) {
        this.blockScopesMap = new Map();
        this.options = options;
        this.identifierNamesGenerator = identifierNamesGeneratorFactory(options);
    }
    storeGlobalName(identifierNode, lexicalScopeNode) {
        const identifierName = identifierNode.name;
        if (this.isReservedName(identifierName)) {
            return;
        }
        const newIdentifierName = this.identifierNamesGenerator.generateForGlobalScope();
        if (!this.blockScopesMap.has(lexicalScopeNode)) {
            this.blockScopesMap.set(lexicalScopeNode, new Map());
        }
        const namesMap = this.blockScopesMap.get(lexicalScopeNode);
        namesMap.set(identifierName, newIdentifierName);
    }
    storeLocalName(identifierNode, lexicalScopeNode) {
        const identifierName = identifierNode.name;
        if (this.isReservedName(identifierName)) {
            return;
        }
        const newIdentifierName = this.identifierNamesGenerator.generateForLexicalScope(lexicalScopeNode);
        if (!this.blockScopesMap.has(lexicalScopeNode)) {
            this.blockScopesMap.set(lexicalScopeNode, new Map());
        }
        const namesMap = this.blockScopesMap.get(lexicalScopeNode);
        namesMap.set(identifierName, newIdentifierName);
    }
    replace(identifierNode, lexicalScopeNode) {
        let identifierName = identifierNode.name;
        if (this.blockScopesMap.has(lexicalScopeNode)) {
            const namesMap = this.blockScopesMap.get(lexicalScopeNode);
            if (namesMap.has(identifierName)) {
                identifierName = namesMap.get(identifierName);
            }
        }
        return NodeFactory_1.NodeFactory.identifierNode(identifierName);
    }
    preserveName(identifierNode) {
        this.identifierNamesGenerator.preserveName(identifierNode.name);
    }
    preserveNameForLexicalScope(identifierNode, lexicalScopeNode) {
        this.identifierNamesGenerator.preserveNameForLexicalScope(identifierNode.name, lexicalScopeNode);
    }
    isReservedName(name) {
        if (!this.options.reservedNames.length) {
            return false;
        }
        return this.options.reservedNames
            .some((reservedName) => {
            return new RegExp(reservedName, 'g').exec(name) !== null;
        });
    }
};
IdentifierReplacer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object])
], IdentifierReplacer);
exports.IdentifierReplacer = IdentifierReplacer;
