"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceMapBaseUrlRule = void 0;
const SourceMapBaseUrlRule = (options) => {
    const { sourceMapBaseUrl } = options;
    if (!options.sourceMapFileName) {
        options = Object.assign(Object.assign({}, options), { sourceMapBaseUrl: '' });
        return options;
    }
    if (sourceMapBaseUrl && !sourceMapBaseUrl.endsWith('/')) {
        options = Object.assign(Object.assign({}, options), { sourceMapBaseUrl: `${sourceMapBaseUrl}/` });
    }
    return options;
};
exports.SourceMapBaseUrlRule = SourceMapBaseUrlRule;
