"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayScopeCallsWrapperTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const StringArrayCustomNode_1 = require("../../enums/custom-nodes/StringArrayCustomNode");
const StringArrayWrappersType_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayWrappersType");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeAppender_1 = require("../../node/NodeAppender");
const NodeGuards_1 = require("../../node/NodeGuards");
let StringArrayScopeCallsWrapperTransformer = class StringArrayScopeCallsWrapperTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options, visitedLexicalScopeNodesStackStorage, stringArrayStorage, stringArrayScopeCallsWrapperNamesDataStorage, stringArrayScopeCallsWrapperLexicalScopeDataStorage, stringArrayTransformerCustomNodeFactory) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.StringArrayRotateFunctionTransformer
        ];
        this.visitedLexicalScopeNodesStackStorage = visitedLexicalScopeNodesStackStorage;
        this.stringArrayStorage = stringArrayStorage;
        this.stringArrayScopeCallsWrapperNamesDataStorage = stringArrayScopeCallsWrapperNamesDataStorage;
        this.stringArrayScopeCallsWrapperLexicalScopeDataStorage = stringArrayScopeCallsWrapperLexicalScopeDataStorage;
        this.stringArrayTransformerCustomNodeFactory = stringArrayTransformerCustomNodeFactory;
    }
    getVisitor(nodeTransformationStage) {
        if (!this.options.stringArrayWrappersCount) {
            return null;
        }
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.StringArray:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isNodeWithLexicalScopeStatements(node, parentNode)) {
                            this.onLexicalScopeNodeEnter(node);
                        }
                    },
                    leave: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isNodeWithLexicalScopeStatements(node, parentNode)) {
                            this.onLexicalScopeNodeLeave();
                            return this.transformNode(node);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(lexicalScopeBodyNode) {
        var _a, _b;
        const stringArrayScopeCallsWrapperNamesDataByEncoding = (_a = this.stringArrayScopeCallsWrapperNamesDataStorage.get(lexicalScopeBodyNode)) !== null && _a !== void 0 ? _a : null;
        const stringArrayScopeCallsWrapperLexicalScopeData = (_b = this.stringArrayScopeCallsWrapperLexicalScopeDataStorage.get(lexicalScopeBodyNode)) !== null && _b !== void 0 ? _b : null;
        if (!stringArrayScopeCallsWrapperNamesDataByEncoding || !stringArrayScopeCallsWrapperLexicalScopeData) {
            return lexicalScopeBodyNode;
        }
        const { callsWrappersParameterIndexesData: stringArrayScopeCallsWrapperParameterIndexes } = stringArrayScopeCallsWrapperLexicalScopeData;
        const stringArrayScopeCallsWrapperNamesDataList = Object.values(stringArrayScopeCallsWrapperNamesDataByEncoding);
        for (const stringArrayScopeCallsWrapperNamesData of stringArrayScopeCallsWrapperNamesDataList) {
            if (!stringArrayScopeCallsWrapperNamesData) {
                continue;
            }
            const { names } = stringArrayScopeCallsWrapperNamesData;
            const namesLength = names.length;
            for (let i = namesLength - 1; i >= 0; i--) {
                const stringArrayScopeCallsWrapperName = names[i];
                const { name: upperStringArrayCallsWrapperName, index: upperStringArrayCallsWrapperShiftedIndex, parameterIndexesData: upperStringArrayCallsWrapperParameterIndexes } = this.getUpperStringArrayCallsWrapperData(stringArrayScopeCallsWrapperNamesData, stringArrayScopeCallsWrapperLexicalScopeData);
                const stringArrayScopeCallsWrapperNode = this.getStringArrayScopeCallsWrapperNode(stringArrayScopeCallsWrapperName, stringArrayScopeCallsWrapperParameterIndexes, upperStringArrayCallsWrapperName, upperStringArrayCallsWrapperParameterIndexes, upperStringArrayCallsWrapperShiftedIndex);
                NodeAppender_1.NodeAppender.prepend(lexicalScopeBodyNode, stringArrayScopeCallsWrapperNode);
            }
        }
        return lexicalScopeBodyNode;
    }
    getRootStringArrayCallsWrapperData(stringArrayScopeCallsWrapperNamesData, stringArrayScopeCallsWrapperLexicalScopeData) {
        const { encoding } = stringArrayScopeCallsWrapperNamesData;
        const { resultShiftedIndex } = stringArrayScopeCallsWrapperLexicalScopeData;
        return {
            name: this.stringArrayStorage.getStorageCallsWrapperName(encoding),
            index: resultShiftedIndex,
            parameterIndexesData: null
        };
    }
    getUpperStringArrayCallsWrapperData(stringArrayScopeCallsWrapperNamesData, stringArrayScopeCallsWrapperLexicalScopeData) {
        var _a, _b, _c, _d, _e, _f;
        const { encoding } = stringArrayScopeCallsWrapperNamesData;
        const { scopeShiftedIndex } = stringArrayScopeCallsWrapperLexicalScopeData;
        const rootStringArrayCallsWrapperData = this.getRootStringArrayCallsWrapperData(stringArrayScopeCallsWrapperNamesData, stringArrayScopeCallsWrapperLexicalScopeData);
        if (!this.options.stringArrayWrappersChainedCalls) {
            return rootStringArrayCallsWrapperData;
        }
        const parentLexicalScopeBodyNode = (_a = this.visitedLexicalScopeNodesStackStorage.getLastElement()) !== null && _a !== void 0 ? _a : null;
        if (!parentLexicalScopeBodyNode) {
            return rootStringArrayCallsWrapperData;
        }
        const parentLexicalScopeNamesDataByEncoding = (_b = this.stringArrayScopeCallsWrapperNamesDataStorage
            .get(parentLexicalScopeBodyNode)) !== null && _b !== void 0 ? _b : null;
        const parentScopeCallsWrapperLexicalScopeData = (_c = this.stringArrayScopeCallsWrapperLexicalScopeDataStorage
            .get(parentLexicalScopeBodyNode)) !== null && _c !== void 0 ? _c : null;
        const parentLexicalScopeNames = (_e = (_d = parentLexicalScopeNamesDataByEncoding === null || parentLexicalScopeNamesDataByEncoding === void 0 ? void 0 : parentLexicalScopeNamesDataByEncoding[encoding]) === null || _d === void 0 ? void 0 : _d.names) !== null && _e !== void 0 ? _e : null;
        if (!(parentLexicalScopeNames === null || parentLexicalScopeNames === void 0 ? void 0 : parentLexicalScopeNames.length)) {
            return rootStringArrayCallsWrapperData;
        }
        const upperStringArrayCallsWrapperName = this.randomGenerator
            .getRandomGenerator()
            .pickone(parentLexicalScopeNames);
        const parameterIndexesData = (_f = parentScopeCallsWrapperLexicalScopeData === null || parentScopeCallsWrapperLexicalScopeData === void 0 ? void 0 : parentScopeCallsWrapperLexicalScopeData.callsWrappersParameterIndexesData) !== null && _f !== void 0 ? _f : null;
        return {
            name: upperStringArrayCallsWrapperName,
            index: scopeShiftedIndex,
            parameterIndexesData
        };
    }
    getStringArrayScopeCallsWrapperNode(stringArrayScopeCallsWrapperName, stringArrayScopeCallsWrapperParameterIndexes, upperStringArrayCallsWrapperName, upperStringArrayCallsWrapperParameterIndexes, stringArrayScopeCallsWrapperShiftedIndex) {
        switch (this.options.stringArrayWrappersType) {
            case StringArrayWrappersType_1.StringArrayWrappersType.Function:
                return this.getStringArrayScopeCallsWrapperFunctionNode(stringArrayScopeCallsWrapperName, stringArrayScopeCallsWrapperParameterIndexes, upperStringArrayCallsWrapperName, upperStringArrayCallsWrapperParameterIndexes, stringArrayScopeCallsWrapperShiftedIndex);
            case StringArrayWrappersType_1.StringArrayWrappersType.Variable:
            default:
                return this.getStringArrayScopeCallsWrapperVariableNode(stringArrayScopeCallsWrapperName, upperStringArrayCallsWrapperName);
        }
    }
    getStringArrayScopeCallsWrapperVariableNode(stringArrayScopeCallsWrapperName, upperStringArrayCallsWrapperName) {
        const stringArrayScopeCallsWrapperVariableNode = this.stringArrayTransformerCustomNodeFactory(StringArrayCustomNode_1.StringArrayCustomNode.StringArrayScopeCallsWrapperVariableNode);
        stringArrayScopeCallsWrapperVariableNode.initialize(stringArrayScopeCallsWrapperName, upperStringArrayCallsWrapperName);
        return stringArrayScopeCallsWrapperVariableNode.getNode();
    }
    getStringArrayScopeCallsWrapperFunctionNode(stringArrayScopeCallsWrapperName, stringArrayScopeCallsWrapperParameterIndexes, upperStringArrayCallsWrapperName, upperStringArrayCallsWrapperParameterIndexes, stringArrayScopeCallsWrapperShiftedIndex) {
        const stringArrayScopeCallsWrapperFunctionNode = this.stringArrayTransformerCustomNodeFactory(StringArrayCustomNode_1.StringArrayCustomNode.StringArrayScopeCallsWrapperFunctionNode);
        stringArrayScopeCallsWrapperFunctionNode.initialize(stringArrayScopeCallsWrapperName, stringArrayScopeCallsWrapperParameterIndexes, upperStringArrayCallsWrapperName, upperStringArrayCallsWrapperParameterIndexes, stringArrayScopeCallsWrapperShiftedIndex);
        return stringArrayScopeCallsWrapperFunctionNode.getNode();
    }
    onLexicalScopeNodeEnter(lexicalScopeBodyNode) {
        this.visitedLexicalScopeNodesStackStorage.push(lexicalScopeBodyNode);
    }
    onLexicalScopeNodeLeave() {
        this.visitedLexicalScopeNodesStackStorage.pop();
    }
};
StringArrayScopeCallsWrapperTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IVisitedLexicalScopeNodesStackStorage)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayScopeCallsWrapperNamesDataStorage)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayScopeCallsWrapperLexicalScopeDataStorage)),
    __param(6, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayCustomNode)),
    __metadata("design:paramtypes", [Object, Object, Object, Object, Object, Object, Function])
], StringArrayScopeCallsWrapperTransformer);
exports.StringArrayScopeCallsWrapperTransformer = StringArrayScopeCallsWrapperTransformer;
