"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var SplitStringTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.SplitStringTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("estraverse"));
const stringz = __importStar(require("stringz"));
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeLiteralUtils_1 = require("../../node/NodeLiteralUtils");
const NodeUtils_1 = require("../../node/NodeUtils");
let SplitStringTransformer = SplitStringTransformer_1 = class SplitStringTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.ObjectExpressionKeysTransformer,
            NodeTransformer_1.NodeTransformer.TemplateLiteralTransformer
        ];
    }
    static chunkString(string, stringLength, chunkSize) {
        const chunksCount = Math.ceil(stringLength / chunkSize);
        const chunks = [];
        let nextChunkStartIndex = 0;
        for (let chunkIndex = 0; chunkIndex < chunksCount; ++chunkIndex, nextChunkStartIndex += chunkSize) {
            chunks[chunkIndex] = stringz.substr(string, nextChunkStartIndex, chunkSize);
        }
        return chunks;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Converting:
                return {
                    enter: (node, parentNode) => {
                        if (!this.options.splitStrings) {
                            return;
                        }
                        if (parentNode && NodeGuards_1.NodeGuards.isLiteralNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(literalNode, parentNode) {
        if (NodeLiteralUtils_1.NodeLiteralUtils.isProhibitedLiteralNode(literalNode, parentNode)) {
            return literalNode;
        }
        const firstPassChunksNode = this.transformLiteralNodeByChunkLength(literalNode, parentNode, SplitStringTransformer_1.firstPassChunkLength);
        const secondPassChunksNode = estraverse.replace(firstPassChunksNode, {
            enter: (node, parentNode) => {
                if (parentNode && NodeGuards_1.NodeGuards.isLiteralNode(node)) {
                    return this.transformLiteralNodeByChunkLength(node, parentNode, this.options.splitStringsChunkLength);
                }
            }
        });
        return secondPassChunksNode;
    }
    transformLiteralNodeByChunkLength(literalNode, parentNode, chunkLength) {
        if (!NodeLiteralUtils_1.NodeLiteralUtils.isStringLiteralNode(literalNode)) {
            return literalNode;
        }
        const valueLength = stringz.length(literalNode.value);
        if (chunkLength >= valueLength) {
            return literalNode;
        }
        const stringChunks = SplitStringTransformer_1.chunkString(literalNode.value, valueLength, chunkLength);
        const binaryExpressionNode = this.transformStringChunksToBinaryExpressionNode(stringChunks);
        NodeUtils_1.NodeUtils.parentizeAst(binaryExpressionNode);
        NodeUtils_1.NodeUtils.parentizeNode(binaryExpressionNode, parentNode);
        return binaryExpressionNode;
    }
    transformStringChunksToBinaryExpressionNode(chunks) {
        const firstChunk = chunks.shift();
        const secondChunk = chunks.shift();
        if (!firstChunk || !secondChunk) {
            throw new Error('First and second chunks values should not be empty');
        }
        const initialBinaryExpressionNode = NodeFactory_1.NodeFactory.binaryExpressionNode('+', NodeFactory_1.NodeFactory.literalNode(firstChunk), NodeFactory_1.NodeFactory.literalNode(secondChunk));
        return chunks.reduce((binaryExpressionNode, chunk) => {
            const chunkLiteralNode = NodeFactory_1.NodeFactory.literalNode(chunk);
            return NodeFactory_1.NodeFactory.binaryExpressionNode('+', binaryExpressionNode, chunkLiteralNode);
        }, initialBinaryExpressionNode);
    }
};
SplitStringTransformer.firstPassChunkLength = 1000;
SplitStringTransformer = SplitStringTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], SplitStringTransformer);
exports.SplitStringTransformer = SplitStringTransformer;
