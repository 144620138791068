"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var AbstractControlFlowReplacer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractControlFlowReplacer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
let AbstractControlFlowReplacer = AbstractControlFlowReplacer_1 = class AbstractControlFlowReplacer {
    constructor(controlFlowCustomNodeFactory, randomGenerator, options) {
        this.replacerDataByControlFlowStorageId = new Map();
        this.controlFlowCustomNodeFactory = controlFlowCustomNodeFactory;
        this.randomGenerator = randomGenerator;
        this.options = options;
    }
    static getStorageKeysByIdForCurrentStorage(identifierDataByControlFlowStorageId, controlFlowStorageId) {
        let storageKeysById;
        if (identifierDataByControlFlowStorageId.has(controlFlowStorageId)) {
            storageKeysById = identifierDataByControlFlowStorageId.get(controlFlowStorageId);
        }
        else {
            storageKeysById = new Map();
        }
        return storageKeysById;
    }
    insertCustomNodeToControlFlowStorage(customNode, controlFlowStorage, replacerId, usingExistingIdentifierChance) {
        const controlFlowStorageId = controlFlowStorage.getStorageId();
        const storageKeysById = AbstractControlFlowReplacer_1.getStorageKeysByIdForCurrentStorage(this.replacerDataByControlFlowStorageId, controlFlowStorageId);
        const storageKeysForCurrentId = storageKeysById.get(replacerId);
        if (this.randomGenerator.getMathRandom() < usingExistingIdentifierChance &&
            storageKeysForCurrentId &&
            storageKeysForCurrentId.length) {
            return this.randomGenerator.getRandomGenerator().pickone(storageKeysForCurrentId);
        }
        const generateStorageKey = (length) => {
            const key = this.randomGenerator.getRandomString(length);
            if (controlFlowStorage.getStorage().has(key)) {
                return generateStorageKey(length);
            }
            return key;
        };
        const storageKey = generateStorageKey(5);
        storageKeysById.set(replacerId, [storageKey]);
        this.replacerDataByControlFlowStorageId.set(controlFlowStorageId, storageKeysById);
        controlFlowStorage.set(storageKey, customNode);
        return storageKey;
    }
};
AbstractControlFlowReplacer = AbstractControlFlowReplacer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowCustomNode)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object])
], AbstractControlFlowReplacer);
exports.AbstractControlFlowReplacer = AbstractControlFlowReplacer;
