"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeFactory = void 0;
const escodegen = __importStar(require("@javascript-obfuscator/escodegen"));
const NodeType_1 = require("../enums/node/NodeType");
class NodeFactory {
    static programNode(body = []) {
        return {
            type: NodeType_1.NodeType.Program,
            body,
            sourceType: 'script',
            metadata: { ignoredNode: false }
        };
    }
    static arrayExpressionNode(elements = []) {
        return {
            type: NodeType_1.NodeType.ArrayExpression,
            elements,
            metadata: { ignoredNode: false }
        };
    }
    static assignmentExpressionNode(operator, left, right) {
        return {
            type: NodeType_1.NodeType.AssignmentExpression,
            operator,
            left,
            right,
            metadata: { ignoredNode: false }
        };
    }
    static binaryExpressionNode(operator, left, right) {
        return {
            type: NodeType_1.NodeType.BinaryExpression,
            operator,
            left,
            right,
            metadata: { ignoredNode: false }
        };
    }
    static blockStatementNode(body = []) {
        return {
            type: NodeType_1.NodeType.BlockStatement,
            body,
            metadata: { ignoredNode: false }
        };
    }
    static breakStatement(label) {
        return {
            type: NodeType_1.NodeType.BreakStatement,
            label,
            metadata: { ignoredNode: false }
        };
    }
    static callExpressionNode(callee, args = [], optional = false) {
        return {
            type: NodeType_1.NodeType.CallExpression,
            callee,
            optional,
            arguments: args,
            metadata: { ignoredNode: false }
        };
    }
    static conditionalExpressionNode(test, consequent, alternate) {
        return {
            type: NodeType_1.NodeType.ConditionalExpression,
            test,
            consequent,
            alternate,
            metadata: { ignoredNode: false }
        };
    }
    static continueStatement(label) {
        return {
            type: NodeType_1.NodeType.ContinueStatement,
            label,
            metadata: { ignoredNode: false }
        };
    }
    static directiveNode(expression, directive) {
        return {
            type: NodeType_1.NodeType.ExpressionStatement,
            expression,
            directive,
            metadata: { ignoredNode: false }
        };
    }
    static doWhileStatementNode(body, test) {
        return {
            type: NodeType_1.NodeType.DoWhileStatement,
            body,
            test,
            metadata: { ignoredNode: false }
        };
    }
    static exportAllDeclarationNode(source) {
        return {
            type: NodeType_1.NodeType.ExportAllDeclaration,
            source,
            metadata: { ignoredNode: false }
        };
    }
    static exportNamedDeclarationNode(specifiers, source) {
        return {
            type: NodeType_1.NodeType.ExportNamedDeclaration,
            specifiers,
            source,
            metadata: { ignoredNode: false }
        };
    }
    static expressionStatementNode(expression) {
        return {
            type: NodeType_1.NodeType.ExpressionStatement,
            expression,
            metadata: { ignoredNode: false }
        };
    }
    static forStatementNode(init, test, update, body) {
        return {
            type: NodeType_1.NodeType.ForStatement,
            init,
            test,
            update,
            body,
            metadata: { ignoredNode: false }
        };
    }
    static forInStatementNode(left, right, body) {
        return {
            type: NodeType_1.NodeType.ForInStatement,
            left,
            right,
            body,
            metadata: { ignoredNode: false }
        };
    }
    static forOfStatementNode(await, left, right, body) {
        return {
            type: NodeType_1.NodeType.ForOfStatement,
            await,
            left,
            right,
            body,
            metadata: { ignoredNode: false }
        };
    }
    static functionDeclarationNode(functionName, params, body) {
        return {
            type: NodeType_1.NodeType.FunctionDeclaration,
            id: NodeFactory.identifierNode(functionName),
            params,
            body,
            generator: false,
            metadata: { ignoredNode: false }
        };
    }
    static functionExpressionNode(params, body) {
        return {
            type: NodeType_1.NodeType.FunctionExpression,
            params,
            body,
            generator: false,
            metadata: { ignoredNode: false }
        };
    }
    static ifStatementNode(test, consequent, alternate) {
        return Object.assign(Object.assign({ type: NodeType_1.NodeType.IfStatement, test,
            consequent }, alternate && { alternate }), { metadata: { ignoredNode: false } });
    }
    static identifierNode(name) {
        return {
            type: NodeType_1.NodeType.Identifier,
            name,
            metadata: { ignoredNode: false }
        };
    }
    static importDeclarationNode(specifiers, source) {
        return {
            type: NodeType_1.NodeType.ImportDeclaration,
            specifiers,
            source,
            metadata: { ignoredNode: false }
        };
    }
    static labeledStatementNode(label, body) {
        return {
            type: NodeType_1.NodeType.LabeledStatement,
            label,
            body,
            metadata: { ignoredNode: false }
        };
    }
    static literalNode(value, raw) {
        raw = raw !== undefined ? raw : `'${value}'`;
        return {
            type: NodeType_1.NodeType.Literal,
            value,
            raw,
            'x-verbatim-property': {
                content: raw,
                precedence: escodegen.Precedence.Primary
            },
            metadata: { ignoredNode: false }
        };
    }
    static logicalExpressionNode(operator, left, right) {
        return {
            type: NodeType_1.NodeType.LogicalExpression,
            operator,
            left,
            right,
            metadata: { ignoredNode: false }
        };
    }
    static memberExpressionNode(object, property, computed = false, optional = false) {
        return {
            type: NodeType_1.NodeType.MemberExpression,
            computed,
            object,
            optional,
            property,
            metadata: { ignoredNode: false }
        };
    }
    static objectExpressionNode(properties) {
        return {
            type: NodeType_1.NodeType.ObjectExpression,
            properties,
            metadata: { ignoredNode: false }
        };
    }
    static propertyNode(key, value, computed = false) {
        return {
            type: NodeType_1.NodeType.Property,
            key,
            value,
            kind: 'init',
            method: false,
            shorthand: false,
            computed,
            metadata: { ignoredNode: false }
        };
    }
    static restElementNode(argument) {
        return {
            type: NodeType_1.NodeType.RestElement,
            argument,
            metadata: { ignoredNode: false }
        };
    }
    static returnStatementNode(argument) {
        return {
            type: NodeType_1.NodeType.ReturnStatement,
            argument,
            metadata: { ignoredNode: false }
        };
    }
    static sequenceExpressionNode(expressions) {
        return {
            type: NodeType_1.NodeType.SequenceExpression,
            expressions,
            metadata: { ignoredNode: false }
        };
    }
    static spreadElementNode(argument) {
        return {
            type: NodeType_1.NodeType.SpreadElement,
            argument,
            metadata: { ignoredNode: false }
        };
    }
    static switchStatementNode(discriminant, cases) {
        return {
            type: NodeType_1.NodeType.SwitchStatement,
            discriminant,
            cases,
            metadata: { ignoredNode: false }
        };
    }
    static switchCaseNode(test, consequent) {
        return {
            type: NodeType_1.NodeType.SwitchCase,
            test,
            consequent,
            metadata: { ignoredNode: false }
        };
    }
    static unaryExpressionNode(operator, argument, prefix = true) {
        return {
            type: NodeType_1.NodeType.UnaryExpression,
            operator,
            argument,
            prefix,
            metadata: { ignoredNode: false }
        };
    }
    static updateExpressionNode(operator, argumentExpr) {
        return {
            type: NodeType_1.NodeType.UpdateExpression,
            operator,
            argument: argumentExpr,
            prefix: false,
            metadata: { ignoredNode: false }
        };
    }
    static variableDeclarationNode(declarations = [], kind = 'var') {
        return {
            type: NodeType_1.NodeType.VariableDeclaration,
            declarations,
            kind,
            metadata: { ignoredNode: false }
        };
    }
    static variableDeclaratorNode(id, init) {
        return {
            type: NodeType_1.NodeType.VariableDeclarator,
            id,
            init,
            metadata: { ignoredNode: false }
        };
    }
    static whileStatementNode(test, body) {
        return {
            type: NodeType_1.NodeType.WhileStatement,
            test,
            body,
            metadata: { ignoredNode: false }
        };
    }
}
exports.NodeFactory = NodeFactory;
