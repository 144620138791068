"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_PRESET = void 0;
const IdentifierNamesGenerator_1 = require("../../enums/generators/identifier-names-generators/IdentifierNamesGenerator");
const ObfuscationTarget_1 = require("../../enums/ObfuscationTarget");
const OptionsPreset_1 = require("../../enums/options/presets/OptionsPreset");
const RenamePropertiesMode_1 = require("../../enums/node-transformers/rename-properties-transformers/RenamePropertiesMode");
const SourceMapMode_1 = require("../../enums/source-map/SourceMapMode");
const StringArrayIndexesType_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayIndexesType");
const StringArrayEncoding_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayEncoding");
const StringArrayWrappersType_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayWrappersType");
exports.DEFAULT_PRESET = Object.freeze({
    compact: true,
    config: '',
    controlFlowFlattening: false,
    controlFlowFlatteningThreshold: 0.75,
    deadCodeInjection: false,
    deadCodeInjectionThreshold: 0.4,
    debugProtection: false,
    debugProtectionInterval: false,
    disableConsoleOutput: false,
    domainLock: [],
    exclude: [],
    forceTransformStrings: [],
    identifierNamesGenerator: IdentifierNamesGenerator_1.IdentifierNamesGenerator.HexadecimalIdentifierNamesGenerator,
    identifiersPrefix: '',
    identifiersDictionary: [],
    ignoreRequireImports: false,
    inputFileName: '',
    log: false,
    numbersToExpressions: false,
    optionsPreset: OptionsPreset_1.OptionsPreset.Default,
    renameGlobals: false,
    renameProperties: false,
    renamePropertiesMode: RenamePropertiesMode_1.RenamePropertiesMode.Safe,
    reservedNames: [],
    reservedStrings: [],
    rotateStringArray: true,
    seed: 0,
    selfDefending: false,
    shuffleStringArray: true,
    simplify: true,
    sourceMap: false,
    sourceMapBaseUrl: '',
    sourceMapFileName: '',
    sourceMapMode: SourceMapMode_1.SourceMapMode.Separate,
    splitStrings: false,
    splitStringsChunkLength: 10,
    stringArray: true,
    stringArrayEncoding: [
        StringArrayEncoding_1.StringArrayEncoding.None
    ],
    stringArrayIndexesType: [
        StringArrayIndexesType_1.StringArrayIndexesType.HexadecimalNumber
    ],
    stringArrayIndexShift: true,
    stringArrayWrappersChainedCalls: true,
    stringArrayWrappersCount: 1,
    stringArrayWrappersParametersMaxCount: 2,
    stringArrayWrappersType: StringArrayWrappersType_1.StringArrayWrappersType.Variable,
    stringArrayThreshold: 0.75,
    target: ObfuscationTarget_1.ObfuscationTarget.Browser,
    transformObjectKeys: false,
    unicodeEscapeSequence: false
});
