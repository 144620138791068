"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customCodeHelpersModule = void 0;
const InversifyContainerFacade_1 = require("../../InversifyContainerFacade");
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const CustomCodeHelper_1 = require("../../../enums/custom-code-helpers/CustomCodeHelper");
const CustomCodeHelperGroup_1 = require("../../../enums/custom-code-helpers/CustomCodeHelperGroup");
const ConsoleOutputCodeHelperGroup_1 = require("../../../custom-code-helpers/console-output/group/ConsoleOutputCodeHelperGroup");
const DebugProtectionCodeHelperGroup_1 = require("../../../custom-code-helpers/debug-protection/group/DebugProtectionCodeHelperGroup");
const DomainLockCustomCodeHelperGroup_1 = require("../../../custom-code-helpers/domain-lock/group/DomainLockCustomCodeHelperGroup");
const SelfDefendingCodeHelperGroup_1 = require("../../../custom-code-helpers/self-defending/group/SelfDefendingCodeHelperGroup");
const StringArrayCodeHelperGroup_1 = require("../../../custom-code-helpers/string-array/group/StringArrayCodeHelperGroup");
const ConsoleOutputDisableCodeHelper_1 = require("../../../custom-code-helpers/console-output/ConsoleOutputDisableCodeHelper");
const CustomCodeHelperFormatter_1 = require("../../../custom-code-helpers/CustomCodeHelperFormatter");
const CustomCodeHelperObfuscator_1 = require("../../../custom-code-helpers/CustomCodeHelperObfuscator");
const DebugProtectionFunctionCallCodeHelper_1 = require("../../../custom-code-helpers/debug-protection/DebugProtectionFunctionCallCodeHelper");
const DebugProtectionFunctionIntervalCodeHelper_1 = require("../../../custom-code-helpers/debug-protection/DebugProtectionFunctionIntervalCodeHelper");
const DebugProtectionFunctionCodeHelper_1 = require("../../../custom-code-helpers/debug-protection/DebugProtectionFunctionCodeHelper");
const DomainLockCodeHelper_1 = require("../../../custom-code-helpers/domain-lock/DomainLockCodeHelper");
const CallsControllerFunctionCodeHelper_1 = require("../../../custom-code-helpers/calls-controller/CallsControllerFunctionCodeHelper");
const SelfDefendingUnicodeCodeHelper_1 = require("../../../custom-code-helpers/self-defending/SelfDefendingUnicodeCodeHelper");
const StringArrayCallsWrapperCodeHelper_1 = require("../../../custom-code-helpers/string-array/StringArrayCallsWrapperCodeHelper");
const StringArrayCallsWrapperBase64CodeHelper_1 = require("../../../custom-code-helpers/string-array/StringArrayCallsWrapperBase64CodeHelper");
const StringArrayCallsWrapperRc4CodeHelper_1 = require("../../../custom-code-helpers/string-array/StringArrayCallsWrapperRc4CodeHelper");
const StringArrayCodeHelper_1 = require("../../../custom-code-helpers/string-array/StringArrayCodeHelper");
const StringArrayRotateFunctionCodeHelper_1 = require("../../../custom-code-helpers/string-array/StringArrayRotateFunctionCodeHelper");
exports.customCodeHelpersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(ConsoleOutputDisableCodeHelper_1.ConsoleOutputDisableCodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.ConsoleOutputDisable);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(DebugProtectionFunctionCallCodeHelper_1.DebugProtectionFunctionCallCodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunctionCall);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(DebugProtectionFunctionIntervalCodeHelper_1.DebugProtectionFunctionIntervalCodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunctionInterval);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(DebugProtectionFunctionCodeHelper_1.DebugProtectionFunctionCodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunction);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(DomainLockCodeHelper_1.DomainLockCodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.DomainLock);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(CallsControllerFunctionCodeHelper_1.CallsControllerFunctionCodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.CallsControllerFunction);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(SelfDefendingUnicodeCodeHelper_1.SelfDefendingUnicodeCodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.SelfDefendingUnicode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(StringArrayCallsWrapperCodeHelper_1.StringArrayCallsWrapperCodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.StringArrayCallsWrapper);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(StringArrayCallsWrapperBase64CodeHelper_1.StringArrayCallsWrapperBase64CodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.StringArrayCallsWrapperBase64);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(StringArrayCallsWrapperRc4CodeHelper_1.StringArrayCallsWrapperRc4CodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.StringArrayCallsWrapperRc4);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(StringArrayCodeHelper_1.StringArrayCodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.StringArray);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper)
        .to(StringArrayRotateFunctionCodeHelper_1.StringArrayRotateFunctionCodeHelper)
        .whenTargetNamed(CustomCodeHelper_1.CustomCodeHelper.StringArrayRotateFunction);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperGroup)
        .to(ConsoleOutputCodeHelperGroup_1.ConsoleOutputCodeHelperGroup)
        .whenTargetNamed(CustomCodeHelperGroup_1.CustomCodeHelperGroup.ConsoleOutput);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperGroup)
        .to(DebugProtectionCodeHelperGroup_1.DebugProtectionCodeHelperGroup)
        .whenTargetNamed(CustomCodeHelperGroup_1.CustomCodeHelperGroup.DebugProtection);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperGroup)
        .to(DomainLockCustomCodeHelperGroup_1.DomainLockCustomCodeHelperGroup)
        .whenTargetNamed(CustomCodeHelperGroup_1.CustomCodeHelperGroup.DomainLock);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperGroup)
        .to(SelfDefendingCodeHelperGroup_1.SelfDefendingCodeHelperGroup)
        .whenTargetNamed(CustomCodeHelperGroup_1.CustomCodeHelperGroup.SelfDefending);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperGroup)
        .to(StringArrayCodeHelperGroup_1.StringArrayCodeHelperGroup)
        .whenTargetNamed(CustomCodeHelperGroup_1.CustomCodeHelperGroup.StringArray);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICustomCodeHelper)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getFactory(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelper));
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICustomCodeHelperGroup)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getFactory(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperGroup));
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)
        .to(CustomCodeHelperFormatter_1.CustomCodeHelperFormatter)
        .inSingletonScope();
    bind(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperObfuscator)
        .to(CustomCodeHelperObfuscator_1.CustomCodeHelperObfuscator)
        .inSingletonScope();
});
