"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.preparingTransformersModule = void 0;
const InversifyContainerFacade_1 = require("../../InversifyContainerFacade");
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const ObfuscatingGuard_1 = require("../../../enums/node-transformers/preparing-transformers/obfuscating-guards/ObfuscatingGuard");
const BlackListObfuscatingGuard_1 = require("../../../node-transformers/preparing-transformers/obfuscating-guards/BlackListObfuscatingGuard");
const ConditionalCommentObfuscatingGuard_1 = require("../../../node-transformers/preparing-transformers/obfuscating-guards/ConditionalCommentObfuscatingGuard");
const CustomCodeHelpersTransformer_1 = require("../../../node-transformers/preparing-transformers/CustomCodeHelpersTransformer");
const EvalCallExpressionTransformer_1 = require("../../../node-transformers/preparing-transformers/EvalCallExpressionTransformer");
const ForceTransformStringObfuscatingGuard_1 = require("../../../node-transformers/preparing-transformers/obfuscating-guards/ForceTransformStringObfuscatingGuard");
const IgnoredRequireImportObfuscatingGuard_1 = require("../../../node-transformers/preparing-transformers/obfuscating-guards/IgnoredRequireImportObfuscatingGuard");
const MetadataTransformer_1 = require("../../../node-transformers/preparing-transformers/MetadataTransformer");
const ObfuscatingGuardsTransformer_1 = require("../../../node-transformers/preparing-transformers/ObfuscatingGuardsTransformer");
const ParentificationTransformer_1 = require("../../../node-transformers/preparing-transformers/ParentificationTransformer");
const ReservedStringObfuscatingGuard_1 = require("../../../node-transformers/preparing-transformers/obfuscating-guards/ReservedStringObfuscatingGuard");
const VariablePreserveTransformer_1 = require("../../../node-transformers/preparing-transformers/VariablePreserveTransformer");
exports.preparingTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(CustomCodeHelpersTransformer_1.CustomCodeHelpersTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.CustomCodeHelpersTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(EvalCallExpressionTransformer_1.EvalCallExpressionTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.EvalCallExpressionTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(MetadataTransformer_1.MetadataTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.MetadataTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ObfuscatingGuardsTransformer_1.ObfuscatingGuardsTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ObfuscatingGuardsTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(ParentificationTransformer_1.ParentificationTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.ParentificationTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(VariablePreserveTransformer_1.VariablePreserveTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.VariablePreserveTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeGuard)
        .to(BlackListObfuscatingGuard_1.BlackListObfuscatingGuard)
        .inSingletonScope()
        .whenTargetNamed(ObfuscatingGuard_1.ObfuscatingGuard.BlackListObfuscatingGuard);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeGuard)
        .to(ConditionalCommentObfuscatingGuard_1.ConditionalCommentObfuscatingGuard)
        .inSingletonScope()
        .whenTargetNamed(ObfuscatingGuard_1.ObfuscatingGuard.ConditionalCommentObfuscatingGuard);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeGuard)
        .to(ForceTransformStringObfuscatingGuard_1.ForceTransformStringObfuscatingGuard)
        .inSingletonScope()
        .whenTargetNamed(ObfuscatingGuard_1.ObfuscatingGuard.ForceTransformStringObfuscatingGuard);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeGuard)
        .to(IgnoredRequireImportObfuscatingGuard_1.IgnoredRequireImportObfuscatingGuard)
        .inSingletonScope()
        .whenTargetNamed(ObfuscatingGuard_1.ObfuscatingGuard.IgnoredRequireImportObfuscatingGuard);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeGuard)
        .to(ReservedStringObfuscatingGuard_1.ReservedStringObfuscatingGuard)
        .inSingletonScope()
        .whenTargetNamed(ObfuscatingGuard_1.ObfuscatingGuard.ReservedStringObfuscatingGuard);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__INodeGuard)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getCacheFactory(ServiceIdentifiers_1.ServiceIdentifiers.INodeGuard));
});
