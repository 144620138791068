"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfDefendingTemplate = void 0;
function SelfDefendingTemplate() {
    return `
        const {selfDefendingFunctionName} = {callControllerFunctionName}(this, function () {
            const test = function () {
                const regExp = test
                    .constructor('return /" + this + "/')()
                    .constructor('^([^ ]+( +[^ ]+)+)+[^ ]}');
                
                return !regExp.test({selfDefendingFunctionName});
            };
            
            return test();
        });
        
        {selfDefendingFunctionName}();
    `;
}
exports.SelfDefendingTemplate = SelfDefendingTemplate;
