"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpressionStatementsMergeTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("estraverse"));
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeStatementUtils_1 = require("../../node/NodeStatementUtils");
const NodeUtils_1 = require("../../node/NodeUtils");
let ExpressionStatementsMergeTransformer = class ExpressionStatementsMergeTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Simplifying:
                return {
                    leave: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isExpressionStatementNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(expressionStatementNode, parentNode) {
        if (!NodeGuards_1.NodeGuards.isNodeWithStatements(parentNode)) {
            return expressionStatementNode;
        }
        const prevStatement = NodeStatementUtils_1.NodeStatementUtils.getPreviousSiblingStatement(expressionStatementNode);
        if (!prevStatement || !NodeGuards_1.NodeGuards.isExpressionStatementNode(prevStatement)) {
            return expressionStatementNode;
        }
        if (NodeGuards_1.NodeGuards.isSequenceExpressionNode(prevStatement.expression)) {
            prevStatement.expression.expressions.push(expressionStatementNode.expression);
            NodeUtils_1.NodeUtils.parentizeNode(expressionStatementNode.expression, prevStatement.expression);
        }
        else {
            prevStatement.expression = NodeFactory_1.NodeFactory.sequenceExpressionNode([
                prevStatement.expression,
                expressionStatementNode.expression
            ]);
            NodeUtils_1.NodeUtils.parentizeAst(prevStatement.expression);
            NodeUtils_1.NodeUtils.parentizeNode(prevStatement.expression, prevStatement);
        }
        return estraverse.VisitorOption.Remove;
    }
};
ExpressionStatementsMergeTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], ExpressionStatementsMergeTransformer);
exports.ExpressionStatementsMergeTransformer = ExpressionStatementsMergeTransformer;
