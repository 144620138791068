"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugProtectionCodeHelperGroup = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const Initializable_1 = require("../../../decorators/Initializable");
const CustomCodeHelper_1 = require("../../../enums/custom-code-helpers/CustomCodeHelper");
const NodeTransformationStage_1 = require("../../../enums/node-transformers/NodeTransformationStage");
const AbstractCustomCodeHelperGroup_1 = require("../../AbstractCustomCodeHelperGroup");
const NodeAppender_1 = require("../../../node/NodeAppender");
const NodeGuards_1 = require("../../../node/NodeGuards");
const NodeLexicalScopeUtils_1 = require("../../../node/NodeLexicalScopeUtils");
let DebugProtectionCodeHelperGroup = class DebugProtectionCodeHelperGroup extends AbstractCustomCodeHelperGroup_1.AbstractCustomCodeHelperGroup {
    constructor(customCodeHelperFactory, identifierNamesGeneratorFactory, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, randomGenerator, options);
        this.customCodeHelperFactory = customCodeHelperFactory;
    }
    appendOnPreparingStage(nodeWithStatements, callsGraphData) {
        var _a;
        if (!this.options.debugProtection) {
            return;
        }
        const randomCallsGraphIndex = this.getRandomCallsGraphIndex(callsGraphData.length);
        const debugProtectionFunctionCallHostNode = callsGraphData.length
            ? NodeAppender_1.NodeAppender.getOptimalBlockScope(callsGraphData, randomCallsGraphIndex)
            : nodeWithStatements;
        const callsControllerHostNode = callsGraphData.length
            ? NodeAppender_1.NodeAppender.getOptimalBlockScope(callsGraphData, randomCallsGraphIndex, 1)
            : nodeWithStatements;
        const debugProtectionFunctionCallScopeNode = (_a = NodeLexicalScopeUtils_1.NodeLexicalScopeUtils
            .getLexicalScope(debugProtectionFunctionCallHostNode)) !== null && _a !== void 0 ? _a : null;
        const debugProtectionFunctionName = debugProtectionFunctionCallScopeNode
            ? this.identifierNamesGenerator.generate(debugProtectionFunctionCallScopeNode)
            : this.identifierNamesGenerator.generateNext();
        const callsControllerFunctionName = debugProtectionFunctionCallScopeNode
            ? this.identifierNamesGenerator.generate(debugProtectionFunctionCallScopeNode)
            : this.identifierNamesGenerator.generateNext();
        this.appendCustomNodeIfExist(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunctionCall, (customCodeHelper) => {
            customCodeHelper.initialize(debugProtectionFunctionName, callsControllerFunctionName);
            NodeAppender_1.NodeAppender.prepend(debugProtectionFunctionCallHostNode, customCodeHelper.getNode());
        });
        this.appendCustomNodeIfExist(CustomCodeHelper_1.CustomCodeHelper.CallsControllerFunction, (customCodeHelper) => {
            customCodeHelper.initialize(NodeTransformationStage_1.NodeTransformationStage.Preparing, callsControllerFunctionName);
            NodeAppender_1.NodeAppender.prepend(callsControllerHostNode, customCodeHelper.getNode());
        });
        this.appendCustomNodeIfExist(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunction, (customCodeHelper) => {
            customCodeHelper.initialize(debugProtectionFunctionName);
            NodeAppender_1.NodeAppender.append(nodeWithStatements, customCodeHelper.getNode());
        });
        this.appendCustomNodeIfExist(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunctionInterval, (customCodeHelper) => {
            const programBodyLength = NodeGuards_1.NodeGuards.isSwitchCaseNode(nodeWithStatements)
                ? nodeWithStatements.consequent.length
                : nodeWithStatements.body.length;
            const randomIndex = this.randomGenerator.getRandomInteger(0, programBodyLength);
            customCodeHelper.initialize(debugProtectionFunctionName);
            NodeAppender_1.NodeAppender.insertAtIndex(nodeWithStatements, customCodeHelper.getNode(), randomIndex);
        });
    }
    initialize() {
        this.customCodeHelpers = new Map();
        if (!this.options.debugProtection) {
            return;
        }
        const debugProtectionFunctionCodeHelper = this.customCodeHelperFactory(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunction);
        const debugProtectionFunctionCallCodeHelper = this.customCodeHelperFactory(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunctionCall);
        const debugProtectionFunctionIntervalCodeHelper = this.customCodeHelperFactory(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunctionInterval);
        const callsControllerFunctionCodeHelper = this.customCodeHelperFactory(CustomCodeHelper_1.CustomCodeHelper.CallsControllerFunction);
        this.customCodeHelpers.set(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunction, debugProtectionFunctionCodeHelper);
        this.customCodeHelpers.set(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunctionCall, debugProtectionFunctionCallCodeHelper);
        if (this.options.debugProtectionInterval) {
            this.customCodeHelpers.set(CustomCodeHelper_1.CustomCodeHelper.DebugProtectionFunctionInterval, debugProtectionFunctionIntervalCodeHelper);
        }
        this.customCodeHelpers.set(CustomCodeHelper_1.CustomCodeHelper.CallsControllerFunction, callsControllerFunctionCodeHelper);
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Map)
], DebugProtectionCodeHelperGroup.prototype, "customCodeHelpers", void 0);
DebugProtectionCodeHelperGroup = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICustomCodeHelper)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Function, Object, Object])
], DebugProtectionCodeHelperGroup);
exports.DebugProtectionCodeHelperGroup = DebugProtectionCodeHelperGroup;
