"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
class Utils {
    static buildVersionMessage(version, buildTimestamp) {
        if (!version || !buildTimestamp) {
            return 'unknown';
        }
        const buildDate = new Date(parseInt(buildTimestamp, 10)).toISOString();
        return `${version}_${buildDate}`;
    }
    static extractDomainFrom(url) {
        let domain;
        if (url.includes('://') || url.indexOf('//') === 0) {
            domain = url.split('/')[2];
        }
        else {
            domain = url.split('/')[0];
        }
        domain = domain.split(':')[0];
        return domain;
    }
    static getIdentifiersPrefixForMultipleSources(identifiersPrefix, sourceCodeIndex) {
        const baseIdentifiersPrefix = !!identifiersPrefix
            ? identifiersPrefix
            : Utils.baseMultipleSourcesIdentifiersPrefix;
        return `${baseIdentifiersPrefix}${sourceCodeIndex}`;
    }
    static makeEnum(enumLikeObject) {
        return Object.freeze(Object.assign({}, enumLikeObject));
    }
}
exports.Utils = Utils;
Utils.baseMultipleSourcesIdentifiersPrefix = 'a';
Utils.hexadecimalPrefix = '0x';
