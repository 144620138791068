"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayCallNode = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const Initializable_1 = require("../../decorators/Initializable");
const AbstractStringArrayCallNode_1 = require("./AbstractStringArrayCallNode");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeUtils_1 = require("../../node/NodeUtils");
let StringArrayCallNode = class StringArrayCallNode extends AbstractStringArrayCallNode_1.AbstractStringArrayCallNode {
    constructor(identifierNamesGeneratorFactory, stringArrayIndexNodeFactory, customCodeHelperFormatter, stringArrayStorage, arrayUtils, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, stringArrayIndexNodeFactory, customCodeHelperFormatter, stringArrayStorage, arrayUtils, randomGenerator, options);
    }
    initialize(stringArrayCallsWrapperName, stringArrayCallsWrapperParameterIndexesData, index, indexShiftAmount, decodeKey) {
        this.stringArrayCallsWrapperName = stringArrayCallsWrapperName;
        this.stringArrayCallsWrapperParameterIndexesData = stringArrayCallsWrapperParameterIndexesData;
        this.index = index;
        this.indexShiftAmount = indexShiftAmount;
        this.decodeKey = decodeKey;
    }
    getNodeStructure() {
        var _a, _b;
        const resultIndex = this.indexShiftAmount + this.index;
        const indexNode = this.getStringArrayIndexNode(resultIndex);
        const rc4KeyLiteralNode = this.decodeKey
            ? this.getRc4KeyLiteralNode(this.decodeKey)
            : null;
        const callExpressionArgs = this.arrayUtils.fillWithRange(!this.stringArrayCallsWrapperParameterIndexesData
            ? AbstractStringArrayCallNode_1.AbstractStringArrayCallNode.stringArrayRootCallsWrapperParametersCount
            : this.options.stringArrayWrappersParametersMaxCount, () => this.getFakeStringArrayIndexNode(resultIndex));
        callExpressionArgs.splice((_b = (_a = this.stringArrayCallsWrapperParameterIndexesData) === null || _a === void 0 ? void 0 : _a.valueIndexParameterIndex) !== null && _b !== void 0 ? _b : 0, 1, indexNode);
        if (this.stringArrayCallsWrapperParameterIndexesData) {
            callExpressionArgs.splice(this.stringArrayCallsWrapperParameterIndexesData.decodeKeyParameterIndex, 1, rc4KeyLiteralNode !== null && rc4KeyLiteralNode !== void 0 ? rc4KeyLiteralNode : this.getFakeStringArrayIndexNode(resultIndex));
        }
        else if (rc4KeyLiteralNode) {
            callExpressionArgs.splice(1, 1, rc4KeyLiteralNode);
        }
        else {
            callExpressionArgs.splice(1, 1);
        }
        const structure = NodeFactory_1.NodeFactory.expressionStatementNode(NodeFactory_1.NodeFactory.callExpressionNode(NodeFactory_1.NodeFactory.identifierNode(this.stringArrayCallsWrapperName), callExpressionArgs));
        NodeUtils_1.NodeUtils.parentizeAst(structure);
        return [structure];
    }
    getFakeStringArrayIndexNode(actualIndex) {
        return this.getStringArrayIndexNode(this.getFakeStringArrayIndex(actualIndex));
    }
    getFakeStringArrayIndex(actualIndex) {
        const stringArrayStorageLength = this.stringArrayStorage.getLength();
        const fakeIndexOffset = stringArrayStorageLength / 2;
        const minimumIndex = actualIndex - fakeIndexOffset;
        const maximumIndex = actualIndex + fakeIndexOffset;
        return this.randomGenerator.getRandomInteger(minimumIndex, maximumIndex);
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Object)
], StringArrayCallNode.prototype, "decodeKey", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Number)
], StringArrayCallNode.prototype, "index", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Number)
], StringArrayCallNode.prototype, "indexShiftAmount", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], StringArrayCallNode.prototype, "stringArrayCallsWrapperName", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", Object)
], StringArrayCallNode.prototype, "stringArrayCallsWrapperParameterIndexesData", void 0);
StringArrayCallNode = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayIndexNode)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(6, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Function, Object, Object, Object, Object, Object])
], StringArrayCallNode);
exports.StringArrayCallNode = StringArrayCallNode;
