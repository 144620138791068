"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfDefendingNoEvalTemplate = void 0;
function SelfDefendingNoEvalTemplate() {
    return `
        const {selfDefendingFunctionName} = {callControllerFunctionName}(this, function () {
            {globalVariableTemplate}
        
            const test = function () {
                const regExp = new that.RegExp('^([^ ]+( +[^ ]+)+)+[^ ]}');
                
                return !regExp.test({selfDefendingFunctionName});
            };
            
            return test();
        });
        
        {selfDefendingFunctionName}();
    `;
}
exports.SelfDefendingNoEvalTemplate = SelfDefendingNoEvalTemplate;
