"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObfuscatingGuard = void 0;
var ObfuscatingGuard;
(function (ObfuscatingGuard) {
    ObfuscatingGuard["BlackListObfuscatingGuard"] = "BlackListObfuscatingGuard";
    ObfuscatingGuard["ConditionalCommentObfuscatingGuard"] = "ConditionalCommentObfuscatingGuard";
    ObfuscatingGuard["ForceTransformStringObfuscatingGuard"] = "ForceTransformStringObfuscatingGuard";
    ObfuscatingGuard["IgnoredRequireImportObfuscatingGuard"] = "IgnoredRequireImportObfuscatingGuard";
    ObfuscatingGuard["ReservedStringObfuscatingGuard"] = "ReservedStringObfuscatingGuard";
})(ObfuscatingGuard = exports.ObfuscatingGuard || (exports.ObfuscatingGuard = {}));
