"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControlFlowFlatteningThresholdRule = void 0;
const ControlFlowFlatteningThresholdRule = (options) => {
    if (options.controlFlowFlatteningThreshold === 0) {
        options = Object.assign(Object.assign({}, options), { controlFlowFlattening: false, controlFlowFlatteningThreshold: 0 });
    }
    return options;
};
exports.ControlFlowFlatteningThresholdRule = ControlFlowFlatteningThresholdRule;
