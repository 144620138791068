"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayCallsWrapperRc4CodeHelper = void 0;
const inversify_1 = require("inversify");
const AtobTemplate_1 = require("./templates/string-array-calls-wrapper/AtobTemplate");
const Rc4Template_1 = require("./templates/string-array-calls-wrapper/Rc4Template");
const StringArrayRC4DecodeTemplate_1 = require("./templates/string-array-calls-wrapper/StringArrayRC4DecodeTemplate");
const StringArrayCallsWrapperCodeHelper_1 = require("./StringArrayCallsWrapperCodeHelper");
let StringArrayCallsWrapperRc4CodeHelper = class StringArrayCallsWrapperRc4CodeHelper extends StringArrayCallsWrapperCodeHelper_1.StringArrayCallsWrapperCodeHelper {
    getDecodeStringArrayTemplate() {
        const atobFunctionName = this.randomGenerator.getRandomString(6);
        const atobPolyfill = this.customCodeHelperFormatter.formatTemplate((0, AtobTemplate_1.AtobTemplate)(), {
            atobFunctionName
        });
        const rc4Polyfill = this.customCodeHelperFormatter.formatTemplate((0, Rc4Template_1.Rc4Template)(), {
            atobFunctionName
        });
        const selfDefendingCode = this.getSelfDefendingTemplate();
        return this.customCodeHelperFormatter.formatTemplate((0, StringArrayRC4DecodeTemplate_1.StringArrayRC4DecodeTemplate)(this.randomGenerator), {
            atobPolyfill,
            rc4Polyfill,
            selfDefendingCode,
            stringArrayName: this.stringArrayName,
            stringArrayCallsWrapperName: this.stringArrayCallsWrapperName
        });
    }
};
StringArrayCallsWrapperRc4CodeHelper = __decorate([
    (0, inversify_1.injectable)()
], StringArrayCallsWrapperRc4CodeHelper);
exports.StringArrayCallsWrapperRc4CodeHelper = StringArrayCallsWrapperRc4CodeHelper;
