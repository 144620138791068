"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputFileNameRule = void 0;
const StringSeparator_1 = require("../../enums/StringSeparator");
const InputFileNameRule = (options) => {
    let { inputFileName } = options;
    if (inputFileName) {
        inputFileName = inputFileName
            .replace(/^\/+/, '')
            .split(StringSeparator_1.StringSeparator.Dot)
            .slice(0, -1)
            .join(StringSeparator_1.StringSeparator.Dot) || inputFileName;
        options = Object.assign(Object.assign({}, options), { inputFileName: `${inputFileName}.js` });
    }
    return options;
};
exports.InputFileNameRule = InputFileNameRule;
