"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeedRule = void 0;
const SeedRule = (options) => {
    if (options.seed) {
        return Object.assign(Object.assign({}, options), { seed: options.seed });
    }
    const getRandomInteger = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };
    return Object.assign(Object.assign({}, options), { seed: getRandomInteger(0, 999999999) });
};
exports.SeedRule = SeedRule;
