"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeadCodeInjectionRule = void 0;
const Default_1 = require("../presets/Default");
const DeadCodeInjectionRule = (options) => {
    if (options.deadCodeInjection) {
        options = Object.assign(Object.assign({}, options), { deadCodeInjection: true, stringArray: true });
        if (!options.stringArrayThreshold) {
            options = Object.assign(Object.assign({}, options), { stringArray: true, stringArrayThreshold: Default_1.DEFAULT_PRESET.stringArrayThreshold });
        }
    }
    return options;
};
exports.DeadCodeInjectionRule = DeadCodeInjectionRule;
