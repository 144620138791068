"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SplitStringsChunkLengthRule = void 0;
const SplitStringsChunkLengthRule = (options) => {
    if (options.splitStringsChunkLength === 0) {
        options = Object.assign(Object.assign({}, options), { splitStrings: false, splitStringsChunkLength: 0 });
    }
    else {
        options = Object.assign(Object.assign({}, options), { splitStringsChunkLength: Math.floor(options.splitStringsChunkLength) });
    }
    return options;
};
exports.SplitStringsChunkLengthRule = SplitStringsChunkLengthRule;
