"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var EvalCallExpressionTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.EvalCallExpressionTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeUtils_1 = require("../../node/NodeUtils");
const StringUtils_1 = require("../../utils/StringUtils");
let EvalCallExpressionTransformer = EvalCallExpressionTransformer_1 = class EvalCallExpressionTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.EscapeSequenceTransformer,
            NodeTransformer_1.NodeTransformer.ParentificationTransformer,
            NodeTransformer_1.NodeTransformer.VariablePreserveTransformer
        ];
        this.evalRootAstHostNodeSet = new Set();
    }
    static extractEvalStringFromCallExpressionArgument(node) {
        if (NodeGuards_1.NodeGuards.isLiteralNode(node)) {
            return EvalCallExpressionTransformer_1.extractEvalStringFromLiteralNode(node);
        }
        if (NodeGuards_1.NodeGuards.isTemplateLiteralNode(node)) {
            return EvalCallExpressionTransformer_1.extractEvalStringFromTemplateLiteralNode(node);
        }
        return null;
    }
    static extractEvalStringFromLiteralNode(node) {
        return typeof node.value === 'string' ? node.value : null;
    }
    static extractEvalStringFromTemplateLiteralNode(node) {
        var _a;
        const quasis = node.quasis;
        const allowedQuasisLength = 1;
        if (quasis.length !== allowedQuasisLength || node.expressions.length) {
            return null;
        }
        return (_a = quasis[0].value.cooked) !== null && _a !== void 0 ? _a : null;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Preparing:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode
                            && NodeGuards_1.NodeGuards.isCallExpressionNode(node)
                            && NodeGuards_1.NodeGuards.isIdentifierNode(node.callee)
                            && node.callee.name === 'eval') {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            case NodeTransformationStage_1.NodeTransformationStage.Finalizing:
                if (!this.evalRootAstHostNodeSet.size) {
                    return null;
                }
                return {
                    leave: (node, parentNode) => {
                        if (parentNode && this.isEvalRootAstHostNode(node)) {
                            return this.restoreNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(callExpressionNode, parentNode) {
        const callExpressionFirstArgument = callExpressionNode.arguments[0];
        if (!callExpressionFirstArgument) {
            return callExpressionNode;
        }
        const evalString = EvalCallExpressionTransformer_1.extractEvalStringFromCallExpressionArgument(callExpressionFirstArgument);
        if (!evalString) {
            return callExpressionNode;
        }
        let ast;
        try {
            ast = NodeUtils_1.NodeUtils.convertCodeToStructure(evalString);
        }
        catch (_a) {
            return callExpressionNode;
        }
        const evalRootAstHostNode = NodeFactory_1.NodeFactory
            .functionExpressionNode([], NodeFactory_1.NodeFactory.blockStatementNode(ast));
        NodeUtils_1.NodeUtils.parentizeAst(evalRootAstHostNode);
        NodeUtils_1.NodeUtils.parentizeNode(evalRootAstHostNode, parentNode);
        this.evalRootAstHostNodeSet.add(evalRootAstHostNode);
        return evalRootAstHostNode;
    }
    restoreNode(evalRootAstHostNode, parentNode) {
        const targetAst = evalRootAstHostNode.body.body;
        const obfuscatedCode = NodeUtils_1.NodeUtils.convertStructureToCode(targetAst);
        return NodeFactory_1.NodeFactory.callExpressionNode(NodeFactory_1.NodeFactory.identifierNode('eval'), [
            NodeFactory_1.NodeFactory.literalNode(StringUtils_1.StringUtils.escapeJsString(obfuscatedCode))
        ]);
    }
    isEvalRootAstHostNode(node) {
        return NodeGuards_1.NodeGuards.isFunctionExpressionNode(node) && this.evalRootAstHostNodeSet.has(node);
    }
};
EvalCallExpressionTransformer = EvalCallExpressionTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], EvalCallExpressionTransformer);
exports.EvalCallExpressionTransformer = EvalCallExpressionTransformer;
