"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var CommentsTransformer_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentsTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("estraverse"));
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const ConditionalCommentObfuscatingGuard_1 = require("../preparing-transformers/obfuscating-guards/ConditionalCommentObfuscatingGuard");
const NodeGuards_1 = require("../../node/NodeGuards");
let CommentsTransformer = CommentsTransformer_1 = class CommentsTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Initializing:
                return {
                    leave: (node) => {
                        if (NodeGuards_1.NodeGuards.isProgramNode(node)) {
                            return this.transformNode(node);
                        }
                    }
                };
            case NodeTransformationStage_1.NodeTransformationStage.Finalizing:
                return {
                    leave: (node) => {
                        if (NodeGuards_1.NodeGuards.isProgramNode(node)) {
                            return this.filterCommentsOnFinalizingTraverse(node);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(rootNode) {
        rootNode = this.filterCommentsOnPrimaryTraverse(rootNode);
        if (!rootNode.comments || !rootNode.comments.length) {
            return rootNode;
        }
        const comments = rootNode.comments.reverse();
        if (comments.length === 0) {
            return rootNode;
        }
        if (!rootNode.body.length) {
            rootNode.leadingComments = comments;
            return rootNode;
        }
        let isFirstNode = true;
        estraverse.traverse(rootNode, {
            enter: (node) => {
                if (node === rootNode) {
                    return;
                }
                const commentIdx = comments.findIndex((comment) => comment.range && node.range && comment.range[0] < node.range[0]);
                if (commentIdx >= 0) {
                    (isFirstNode ? rootNode : node).leadingComments =
                        comments.splice(commentIdx, comments.length - commentIdx).reverse();
                }
                isFirstNode = false;
            }
        });
        if (comments.length > 0) {
            rootNode.trailingComments = comments.reverse();
        }
        return rootNode;
    }
    filterCommentsOnPrimaryTraverse(rootNode) {
        var _a;
        rootNode.comments = (_a = rootNode.comments) === null || _a === void 0 ? void 0 : _a.filter((comment) => this.filterComment(comment, true));
        return rootNode;
    }
    filterCommentsOnFinalizingTraverse(rootNode) {
        estraverse.replace(rootNode, {
            enter: (node) => {
                var _a, _b;
                if (node.leadingComments) {
                    node.leadingComments = (_a = node.leadingComments) === null || _a === void 0 ? void 0 : _a.filter((comment) => this.filterComment(comment, false));
                }
                if (node.trailingComments) {
                    node.trailingComments = (_b = node.trailingComments) === null || _b === void 0 ? void 0 : _b.filter((comment) => this.filterComment(comment, false));
                }
                return node;
            }
        });
        return rootNode;
    }
    filterComment(comment, keepConditionalComment) {
        if (keepConditionalComment && ConditionalCommentObfuscatingGuard_1.ConditionalCommentObfuscatingGuard.isConditionalComment(comment)) {
            return true;
        }
        return CommentsTransformer_1.preservedWords
            .some((preservedWord) => comment.value.includes(preservedWord));
    }
};
CommentsTransformer.preservedWords = [
    '@license',
    '@preserve'
];
CommentsTransformer = CommentsTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], CommentsTransformer);
exports.CommentsTransformer = CommentsTransformer;
