"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfDefendingUnicodeCodeHelper = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const ObfuscationTarget_1 = require("../../enums/ObfuscationTarget");
const Initializable_1 = require("../../decorators/Initializable");
const SelfDefendingTemplate_1 = require("./templates/SelfDefendingTemplate");
const SelfDefendingNoEvalTemplate_1 = require("./templates/SelfDefendingNoEvalTemplate");
const AbstractCustomCodeHelper_1 = require("../AbstractCustomCodeHelper");
const NodeUtils_1 = require("../../node/NodeUtils");
const GlobalVariableNoEvalTemplate_1 = require("../common/templates/GlobalVariableNoEvalTemplate");
let SelfDefendingUnicodeCodeHelper = class SelfDefendingUnicodeCodeHelper extends AbstractCustomCodeHelper_1.AbstractCustomCodeHelper {
    constructor(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options);
    }
    initialize(callsControllerFunctionName, selfDefendingFunctionName) {
        this.callsControllerFunctionName = callsControllerFunctionName;
        this.selfDefendingFunctionName = selfDefendingFunctionName;
    }
    getNodeStructure(codeHelperTemplate) {
        return NodeUtils_1.NodeUtils.convertCodeToStructure(codeHelperTemplate);
    }
    getCodeHelperTemplate() {
        const globalVariableTemplate = this.options.target !== ObfuscationTarget_1.ObfuscationTarget.BrowserNoEval
            ? this.getGlobalVariableTemplate()
            : (0, GlobalVariableNoEvalTemplate_1.GlobalVariableNoEvalTemplate)();
        const selfDefendingTemplate = this.options.target !== ObfuscationTarget_1.ObfuscationTarget.BrowserNoEval
            ? (0, SelfDefendingTemplate_1.SelfDefendingTemplate)()
            : (0, SelfDefendingNoEvalTemplate_1.SelfDefendingNoEvalTemplate)();
        return this.customCodeHelperFormatter.formatTemplate(selfDefendingTemplate, {
            callControllerFunctionName: this.callsControllerFunctionName,
            selfDefendingFunctionName: this.selfDefendingFunctionName,
            globalVariableTemplate
        });
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], SelfDefendingUnicodeCodeHelper.prototype, "callsControllerFunctionName", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], SelfDefendingUnicodeCodeHelper.prototype, "selfDefendingFunctionName", void 0);
SelfDefendingUnicodeCodeHelper = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperObfuscator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Object, Object, Object, Object])
], SelfDefendingUnicodeCodeHelper);
exports.SelfDefendingUnicodeCodeHelper = SelfDefendingUnicodeCodeHelper;
