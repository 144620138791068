"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var HexadecimalIdentifierNamesGenerator_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.HexadecimalIdentifierNamesGenerator = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const AbstractIdentifierNamesGenerator_1 = require("./AbstractIdentifierNamesGenerator");
const NumberUtils_1 = require("../../utils/NumberUtils");
const Utils_1 = require("../../utils/Utils");
let HexadecimalIdentifierNamesGenerator = HexadecimalIdentifierNamesGenerator_1 = class HexadecimalIdentifierNamesGenerator extends AbstractIdentifierNamesGenerator_1.AbstractIdentifierNamesGenerator {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
    }
    generateNext(nameLength) {
        const rangeMinInteger = 10000;
        const rangeMaxInteger = 99999999;
        const randomInteger = this.randomGenerator.getRandomInteger(rangeMinInteger, rangeMaxInteger);
        const hexadecimalNumber = NumberUtils_1.NumberUtils.toHex(randomInteger);
        const prefixLength = Utils_1.Utils.hexadecimalPrefix.length;
        const baseNameLength = (nameLength !== null && nameLength !== void 0 ? nameLength : HexadecimalIdentifierNamesGenerator_1.baseIdentifierNameLength)
            + prefixLength;
        const baseIdentifierName = hexadecimalNumber.slice(0, baseNameLength);
        const identifierName = `_${baseIdentifierName}`;
        if (!this.isValidIdentifierName(identifierName)) {
            return this.generateNext(nameLength);
        }
        this.preserveName(identifierName);
        return identifierName;
    }
    generateForGlobalScope(nameLength) {
        const identifierName = this.generateNext(nameLength);
        return `${this.options.identifiersPrefix}${identifierName}`.replace('__', '_');
    }
    generateForLexicalScope(lexicalScopeNode, nameLength) {
        return this.generateNext(nameLength);
    }
};
HexadecimalIdentifierNamesGenerator.baseIdentifierNameLength = 6;
HexadecimalIdentifierNamesGenerator = HexadecimalIdentifierNamesGenerator_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Object, Object])
], HexadecimalIdentifierNamesGenerator);
exports.HexadecimalIdentifierNamesGenerator = HexadecimalIdentifierNamesGenerator;
