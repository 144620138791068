"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayScopeCallsWrapperVariableNode = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const Initializable_1 = require("../../decorators/Initializable");
const AbstractStringArrayCallNode_1 = require("./AbstractStringArrayCallNode");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeUtils_1 = require("../../node/NodeUtils");
let StringArrayScopeCallsWrapperVariableNode = class StringArrayScopeCallsWrapperVariableNode extends AbstractStringArrayCallNode_1.AbstractStringArrayCallNode {
    constructor(identifierNamesGeneratorFactory, stringArrayIndexNodeFactory, customCodeHelperFormatter, stringArrayStorage, arrayUtils, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, stringArrayIndexNodeFactory, customCodeHelperFormatter, stringArrayStorage, arrayUtils, randomGenerator, options);
    }
    initialize(stringArrayScopeCallsWrapperName, stringArrayCallsWrapperName) {
        this.stringArrayScopeCallsWrapperName = stringArrayScopeCallsWrapperName;
        this.stringArrayCallsWrapperName = stringArrayCallsWrapperName;
    }
    getNodeStructure() {
        const structure = NodeFactory_1.NodeFactory.variableDeclarationNode([
            NodeFactory_1.NodeFactory.variableDeclaratorNode(NodeFactory_1.NodeFactory.identifierNode(this.stringArrayScopeCallsWrapperName), NodeFactory_1.NodeFactory.identifierNode(this.stringArrayCallsWrapperName))
        ], 'const');
        NodeUtils_1.NodeUtils.parentizeAst(structure);
        return [structure];
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], StringArrayScopeCallsWrapperVariableNode.prototype, "stringArrayCallsWrapperName", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], StringArrayScopeCallsWrapperVariableNode.prototype, "stringArrayScopeCallsWrapperName", void 0);
StringArrayScopeCallsWrapperVariableNode = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayIndexNode)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(6, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [Function, Function, Object, Object, Object, Object, Object])
], StringArrayScopeCallsWrapperVariableNode);
exports.StringArrayScopeCallsWrapperVariableNode = StringArrayScopeCallsWrapperVariableNode;
