"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AtobTemplate = void 0;
const Base64AlphabetSwapped_1 = require("../../../../constants/Base64AlphabetSwapped");
function AtobTemplate() {
    return `
        var {atobFunctionName} = function (input) {
            const chars = '${Base64AlphabetSwapped_1.base64alphabetSwapped}';

            let output = '';
            for (
                let bc = 0, bs, buffer, idx = 0;
                buffer = input.charAt(idx++);
                ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
                    bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
            ) {
                buffer = chars.indexOf(buffer);
            }
            return output;
        };
    `;
}
exports.AtobTemplate = AtobTemplate;
