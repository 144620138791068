"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceMapFileNameRule = void 0;
const StringSeparator_1 = require("../../enums/StringSeparator");
const SourceMapFileNameRule = (options) => {
    let { sourceMapFileName } = options;
    if (sourceMapFileName) {
        sourceMapFileName = sourceMapFileName
            .replace(/^\/+/, '')
            .replace(/(?:\.js)?(?:\.map)?$/, '');
        let sourceMapFileNameParts = sourceMapFileName.split(StringSeparator_1.StringSeparator.Dot);
        const sourceMapFileNamePartsCount = sourceMapFileNameParts.length;
        const lastPart = sourceMapFileNameParts[sourceMapFileNamePartsCount - 1];
        if (sourceMapFileNamePartsCount > 1 && lastPart.length <= 3) {
            sourceMapFileNameParts = sourceMapFileNameParts.slice(0, -1);
        }
        sourceMapFileName = sourceMapFileNameParts.join(StringSeparator_1.StringSeparator.Dot);
        options = Object.assign(Object.assign({}, options), { sourceMapFileName: `${sourceMapFileName}.js.map` });
    }
    return options;
};
exports.SourceMapFileNameRule = SourceMapFileNameRule;
