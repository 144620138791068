"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayCallsWrapperTemplate = void 0;
function StringArrayCallsWrapperTemplate() {
    return `
         function {stringArrayCallsWrapperName} (index, key) {
            index = index - {indexShiftAmount};
            
            let value = {stringArrayName}[index];
            
            {decodeCodeHelperTemplate}
        
            return value;
        }
    `;
}
exports.StringArrayCallsWrapperTemplate = StringArrayCallsWrapperTemplate;
